<footer class="bg-white border-t border-gray-100 text-footer">
  <div
    class="sm:px-2 lg:px-6 lg:py-3 sm:py-2 min-h-[52px] flex flex-col-reverse sm:flex-row items-center sm:items-center justify-between"
  >
    <!-- Left side -->
    <div class="text-gray-500 mt-2 sm:mt-0 text-center sm:text-left">© {{ currentYear }} Nicky L.L.C.</div>

    <!-- Right side -->
    <div class="sm:px-1 flex flex-col justify-center sm:justify-end items-center sm:items-end">
      <!-- API service text -->
      <p class="text-gray-500 text-center sm:text-right">
        {{ 'nickyDoestNotHoldFunds' | translate }}
      </p>

      <!-- Links -->
      <nav class="flex justify-center sm:justify-end">
        <ul class="flex items-center sm:items-end justify-center sm:justify-end gap-6">
          @for (link of footerLinks; track link) {
            <li>
              <a
                [href]="link.url"
                class="text-gray-500 hover:text-gray-900 transition-colors duration-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ link.translationKey | translate }}
              </a>
            </li>
          }
        </ul>
      </nav>
    </div>
  </div>
</footer>
