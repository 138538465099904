<nav class="bg-white relative z-50 border-b border-gray-200 border-solid">
  <div class="px-4 lg:px-14 flex items-center justify-between w-full h-[52px] lg:h-[80px]">
    <!-- Left side: Logo and Navigation -->
    <div class="flex items-center gap-12">
      <!-- Logo -->
      <div class="flex shrink-0 items-center">
        <div class="flex items-center">
          <picture (click)="goHome()" class="w-[102px] h-[42px]">
            <source srcset="assets/nicky-logo.svg" type="image/svg+xml" />
            <img class="clickable" src="/assets/nicky-logo.svg" alt="logo" />
          </picture>
          <!-- Route Title -->
          @if (fullWidthMode && routeTitle) {
            <div class="flex items-center ml-2 md:ml-4 lg:ml-6 space-x-2 lg:space-x-4">
              <div class="h-6 border-r border-solid border-gray-300"></div>
              <div class="px-1 lg:px-2">
                <h1
                  class="font-inter text-sm md:text-lg font-semibold leading-6 tracking-tight text-left text-gray-700"
                >
                  {{ routeTitle }}
                </h1>
              </div>
            </div>
          }
        </div>
      </div>

      <!-- Navigation Links -->
      <div class="hidden lg:flex items-center gap-6">
        @if (!fullWidthMode) {
          @for (link of navigationLinks; track link.id) {
            <a
              [id]="link.id"
              [routerLink]="link.path"
              routerLinkActive="bg-black text-white"
              class="px-3 py-2 rounded-xl inline-flex items-center gap-4 font-medium text-black hover:bg-gray-700 hover:text-white"
            >
              <i [class]="link.icon" class="text-xl items-center justify-left" aria-hidden="true"></i>
              <span class="font-inter text-sm font-normal leading-5">
                {{ link.labelKey | translate }}
              </span>
            </a>
          }
        }
      </div>
    </div>

    <!-- Right side: Search, Help, Language, Profile -->
    <div class="flex items-center gap-0.5 lg:gap-0 ml-2">
      <!-- Search Bar -->
      @if (!router.url.includes('payment-report') && !router.url.includes('payment-request')) {
        <div class="max-w-[220px]">
          <app-search-bar-private></app-search-bar-private>
        </div>
      }

      <div class="block md:hidden">
        @if (showHelpButton) {
          <app-nicky-button
            size="extra-small"
            [customLeftClasses]="'leading-none'"
            variant="text"
            (click)="startPageTour()"
          >
            <mat-icon svgIcon="help-book" id="bookIcon"></mat-icon>
          </app-nicky-button>
        }
      </div>

      <div
        class="hidden lg:flex gap-0 mx-2 px-2 lg:mx-2 lg:px-2 lg:gap-2 items-center border-l border-r border-gray-200 border-solid"
      >
        <!-- Help Button -->
        <!-- TODO Move tour header logics to a component -->
        @if (showHelpButton) {
          <app-nicky-button
            size="extra-small"
            [customLeftClasses]="'leading-none'"
            variant="text"
            (click)="startPageTour()"
          >
            <mat-icon svgIcon="help-book" id="bookIcon"></mat-icon>
          </app-nicky-button>
        }

        <!-- Language Dropdown -->
        <app-nicky-button size="nav-button" class="hidden lg:block" variant="text">
          <app-dropdown #dropdown [responsiveLabel]="true" [dropdownEnd]="false">
            <div type="button" style="line-height: 0" id="user-menu-button" dropdown-label aria-haspopup="menu">
              <span class="sr-only">Open language menu</span>
              <mat-icon svgIcon="{{ authStore.profile()?.language }}"></mat-icon>
            </div>
            <div role="language-menu">
              @for (lang of systemLanguages; track lang.language) {
                <a class="dropdown-menu-item" role="language-item" (click)="changeLanguage(lang.language, dropdown)">
                  <mat-icon [svgIcon]="lang.language" class="size-5"></mat-icon>
                  <span
                    >{{ lang.name }} <span class="text-xs text-gray-500">{{ lang.language.toUpperCase() }}</span></span
                  >
                  @if (authStore.profile()?.language === lang.language) {
                    <i class="ph ph-check size-5 ml-auto"></i>
                  }
                </a>
              }
            </div>
          </app-dropdown>
        </app-nicky-button>
      </div>

      <!-- Profile Dropdown -->
      <app-nicky-button size="nav-button" class="hidden lg:block" variant="text">
        <app-dropdown customStyle="padding: 0.5rem" [responsiveLabel]="true">
          <div type="button" class="p-1 shrink-0" id="user-menu-button" dropdown-label aria-haspopup="menu">
            <span class="sr-only">Open profile menu</span>
            <app-user-profile-picture
              [imageUrl]="authStore.profilePicture()"
              [name]="authStore.profile()?.publicName"
              [size]="'sm'"
              [className]="'size-8 rounded-full'"
              class="shrink-0"
            ></app-user-profile-picture>
          </div>
          <div role="menu">
            <!-- Profile Header -->
            <div class="dropdown-profile-header mb-2">
              <app-user-profile-picture
                [imageUrl]="authStore.profilePicture()"
                [name]="authStore.profile()?.publicName"
                [size]="'md'"
                class="shrink-0"
                [className]="'size-8 rounded-full'"
              ></app-user-profile-picture>
              <div class="flex flex-col truncate">
                <div class="text-base font-medium text-gray-800 truncate">{{ authStore.profile()?.publicName }}</div>
                <div class="text-sm text-gray-500 truncate">{{ authStore.profile()?.email }}</div>
              </div>
            </div>

            <!-- Menu Items -->
            @for (link of profileMenuLinks; track link.id) {
              <a
                [routerLink]="link.path"
                class="dropdown-menu-item"
                role="menuitem"
                id="user-menu-item-{{ link.id }}"
                (click)="link.action ? link.action() : null"
              >
                <i [class]="link.icon" class="text-xl text-gray-500"></i>
                <span>{{ link.labelKey | translate }}</span>
              </a>
            }
          </div>
        </app-dropdown>
      </app-nicky-button>

      <!-- Mobile Menu Button -->
      <app-nicky-button class="lg:hidden" size="small" variant="text" (click)="toggleMobileMenu()">
        <span class="sr-only">Open main menu</span>
        @if (!isMobileMenuOpen) {
          <svg
            class="block size-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
            data-slot="icon"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        } @else {
          <svg
            class="block size-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
            data-slot="icon"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        }
      </app-nicky-button>
    </div>
  </div>

  <!-- Mobile Menu -->
  <div
    class="lg:hidden fixed inset-x-0 top-[3.3rem] bottom-0 bg-white z-40"
    id="mobile-menu"
    [class.hidden]="!isMobileMenuOpen"
  >
    <div class="h-full flex flex-col">
      <!-- Mobile Navigation Links -->
      <div class="flex-none px-4 py-3 space-y-6">
        @for (link of navigationLinks; track link.id) {
          <a
            [id]="link.id"
            [routerLink]="link.path"
            routerLinkActive="bg-black text-white"
            class="w-full rounded-xl px-4 py-3 text-sm font-medium text-black hover:bg-gray-100 inline-flex items-center gap-3"
            (click)="toggleMobileMenu()"
          >
            <i [class]="link.icon" class="text-lg" aria-hidden="true"></i>
            <span class="font-inter text-base font-normal">
              {{ link.labelKey | translate }}
            </span>
          </a>
        }
      </div>

      <!-- Mobile Language and Profile -->
      <div class="flex-grow"></div>

      <!-- Mobile Language Selector -->
      <div class="px-4 py-3 border border-solid border-[#EFF0F4] rounded-lg m-4">
        <app-dropdown
          #dropdownMobile
          class="w-full"
          [responsiveLabel]="true"
          [dropdownEnd]="false"
          dropdownPosition="bottom"
          [fullWidth]="true"
        >
          <div type="button" id="mobile-language-button" dropdown-label aria-haspopup="menu">
            <div class="flex items-center gap-3 text-gray-900">
              <mat-icon svgIcon="{{ authStore.profile()?.language }}" class="size-5"></mat-icon>
              <span class="text-base font-normal">
                @for (lang of systemLanguages; track lang.language) {
                  @if (lang.language === authStore.profile()?.language) {
                    {{ lang.name }}
                  }
                }
              </span>
            </div>
          </div>
          <div role="language-menu">
            @for (lang of systemLanguages; track lang.language) {
              <a
                class="dropdown-menu-item"
                role="language-item"
                (click)="changeLanguage(lang.language, dropdownMobile)"
              >
                <mat-icon [svgIcon]="lang.language" class="size-5"></mat-icon>
                <span
                  >{{ lang.name }} <span class="text-xs text-gray-500">{{ lang.language.toUpperCase() }}</span></span
                >
                @if (authStore.profile()?.language === lang.language) {
                  <i class="ph ph-check size-5 ml-auto"></i>
                }
              </a>
            }
          </div>
        </app-dropdown>
      </div>

      <!-- Mobile Profile Menu -->
      <div class="px-4 py-3 border border-solid border-[#EFF0F4] rounded-lg m-4">
        <app-dropdown
          #dropdownMobileProfile
          class="w-full"
          [responsiveLabel]="true"
          dropdownPosition="bottom"
          [fullWidth]="true"
        >
          <div type="button" id="mobile-profile-button" dropdown-label aria-haspopup="menu">
            <div class="flex items-center gap-3 text-gray-900">
              <app-user-profile-picture
                [imageUrl]="authStore.profilePicture()"
                [name]="authStore.profile()?.publicName"
                [size]="'sm'"
                class="shrink-0"
                [className]="'size-8 rounded-full'"
              ></app-user-profile-picture>

              <span class="text-base font-normal">{{ 'HEADER.PROFILE' | translate }}</span>
            </div>
          </div>
          <div role="menu">
            <!-- Profile Header -->
            <div class="dropdown-profile-header mb-2">
              <app-user-profile-picture
                [imageUrl]="authStore.profilePicture()"
                [name]="authStore.profile()?.publicName"
                [size]="'md'"
                [className]="'size-8 rounded-full'"
                class="shrink-0"
              ></app-user-profile-picture>
              <div class="flex flex-col truncate">
                <div class="text-base font-medium text-gray-800 truncate">{{ authStore.profile()?.publicName }}</div>
                <div class="text-sm text-gray-500 truncate">{{ authStore.profile()?.email }}</div>
              </div>
            </div>

            <!-- Menu Items -->
            @for (link of profileMenuLinks; track link.id) {
              <a
                [routerLink]="link.path"
                class="dropdown-menu-item"
                role="menuitem"
                id="user-menu-item-{{ link.id }}"
                (click)="link.action?.(); toggleMobileMenu(); dropdownMobileProfile.close()"
              >
                <i [class]="link.icon" class="text-lg text-gray-500"></i>
                <span>{{ link.labelKey | translate }}</span>
              </a>
            }
          </div>
        </app-dropdown>
      </div>
    </div>
  </div>
</nav>

<!-- Back Button -->
@if (
  authStore.authenticated() && (router.url.includes('/s/') || router.url.includes('/e/') || router.url.includes('/d/'))
) {
  <!-- Back Button -->
  <div class="my-2 mx-2 lg:mx-12 z-50">
    <app-nicky-button iconLeft="ph ph-arrow-left" variant="text" size="small" (click)="handleBackNavigation()">
      {{ 'back' | translate }}
    </app-nicky-button>
  </div>
}
