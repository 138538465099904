import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NickyButtonComponent } from '../../form/nicky-button/nicky-button.component';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
    selector: 'app-domain-owner-modal',
    imports: [TranslateModule, CommonModule, NickyButtonComponent, A11yModule],
    templateUrl: './domain-owner-modal.component.html'
})
export class DomainOwnerModalComponent {
  protected dialogRef = inject(DialogRef);
  protected data = inject(DIALOG_DATA);
}
