export const TOUR_STEPS = {
  // Overview page main tour
  OVERVIEW: {
    MAIN: {
      OVERVIEW: {
        id: 'overview',
        title: 'hereIsTheOverview',
        text: 'hereYouWillFindAGeneralView',
        position: 'bottom',
      },
      BALANCES_SELECTION: {
        id: 'balances-selection',
        title: 'accountBalances',
        text: 'hereYouCanSeeYourBalanceInEach',
        position: 'bottom',
      },
      ADD_WALLET_BUTTON: {
        id: 'wallet-button',
        title: 'addWallet',
        text: 'clickHereToAddWallets',
        position: 'bottom',
      },
      CONTACTS_SELECTION: {
        id: 'contacts-selection',
        title: 'contacts',
        text: 'yourContactsAreNickyUsers',
        position: 'bottom',
      },
      CONTACTS_BUTTON: {
        id: 'contacts-selection-button',
        title: 'contacts',
        text: 'clickHereToSeeABreakdownOfYourContactList',
        position: 'bottom',
      },
      TRANSACTIONS_SELECTION: {
        id: 'transactions-selection',
        title: 'transactions',
        text: 'hereTheTransactionsControlledByNicky',
        position: 'bottom',
      },
      TRANSACTIONS_BUTTON: {
        id: 'transactions-selection-button',
        title: 'transactions',
        text: 'clickHereToSeeABreakdownOfAllYourTransactions',
        position: 'bottom',
      },
    },
    // Overview page section-specific tours
    SECTIONS: {
      BALANCE_SECTION: {
        CRYPTO: {
          id: 'crypto-balance',
          title: 'cryptoBalances',
          text: 'viewYourCryptocurrencyBalancesHere',
          position: 'bottom',
        },
        FIAT: {
          id: 'fiat-balance',
          title: 'fiatBalances',
          text: 'viewYourFiatCurrencyBalancesHere',
          position: 'bottom',
        },
      },
      ACTIVITY_SECTION: {
        RECENT: {
          id: 'recent-activity',
          title: 'recentActivity',
          text: 'yourLatestTransactionsAndActivities',
          position: 'bottom',
        },
        STATS: {
          id: 'activity-stats',
          title: 'activityStatistics',
          text: 'overviewOfYourTransactionPatterns',
          position: 'bottom',
        },
      },
    },
  },

  // Payment Request TRANSACTIONS pages tours
  TRANSACTIONS: {
    TRANSACTIONS_SELECTION: {
      id: 'transactions-selection',
      title: 'hereAreThePaymentReports',
      text: 'paymentReportsAreAmountTransferred',
      position: 'bottom',
    },
    TRANSACTIONS_BUTTON: {
      id: 'reports-tab-button',
      title: 'transactions',
      text: 'paymentReportsAreAmountTransferred',
      position: 'bottom',
    },
    PAYMENT_REQUESTS: {
      id: 'requests-tab-button',
      title: 'paymentRequests',
      text: 'paymentRequestsAreGenerated',
      position: 'bottom',
    },
    PAYMENT_REQUESTS_BUTTON: {
      id: 'payment-request-button',
      title: 'paymentRequests',
      text: 'thisIsWhereYouCreateAPaymentRequest',
      position: 'bottom',
    },
  },

  // Payment Report page tours

  PAYMENT_REPORT: {
    INFORMATION: {
      RECEIVED: {
        id: 'received',
        title: '',
        text: 'thisIsTheAmountChargedInItsOriginalCurrency',
        position: 'bottom'
      },
      PAID: {
        id: 'paid',
        title: '',
        text: 'pleaseMakeSureToUseTheChosenNetworkWhenMakingTheTransaction',
        position: 'bottom'
      },
      RATE: {
        id: 'rate',
        title: '',
        text: 'thisIsTheRateUsedToCalculateTheAmountToBePaid',
        position: 'bottom'
      },
      NEXT_BUTTON: {
        id: 'next-button',
        title: '',
        text: 'clickNextToContinueWithYourPayment',
        position: 'bottom'
      }
    },
    PAYMENT: {
      AMOUNT_RECEIVED: {
        id: 'amount-received',
        title: '',
        text: 'thisIsTheAmountChargedInItsOriginalCurrency',
        position: 'bottom'
      },
      AMOUNT_PAID: {
        id: 'amount-paid',
        title: '',
        text: 'amountThatYouWillPay',
        position: 'bottom'
      },
      PAYMENT_DEADLINE: {
        id: 'payment-deadline',
        title: '',
        text: 'youNeedToFinishPayingWithinThisTime',
        position: 'bottom'
      },
      CURRENT_RATE: {
        id: 'current-rate',
        title: '',
        text: 'rateUsedToCalculateAmount',
        position: 'bottom'
      },
      PAYMENT_ADDRESS: {
        id: 'payment-address',
        title: '',
        text: 'payToThisAddress',
        position: 'bottom'
      },
      CONFIRMATION_STEP: {
        id: 'confirmation-step',
        title: '',
        text: 'confirmYouHaveSent',
        position: 'bottom'
      }
    }
  },

  // Public Profile page tours
  PUBLIC_PROFILE: {
    PROFILE_CARD: {
      id: 'profile-card',
      title: 'profileInformation',
      text: 'hereYouCanSeeEverythingAboutYourNickyProfile',
      position: 'bottom',
    },
    PROFILE_ID: {
      id: 'profile-id',
      title: 'profileId',
      text: 'thatsYourShortIDThatsYourNickyID',
      position: 'bottom',
    },
    PROFILE_SHARE_BUTTON: {
      id: 'profile-share-button',
      title: 'shareProfile',
      text: 'hereYouAccessYourProfileLinkOrQRCodeToShare',
      position: 'bottom',
    },
    QR_CODE: {
      id: 'qr-code',
      title: 'qrCode',
      text: 'hereYouCanSeeTheQRCodeOfYourProfileToShareIt',
      position: 'bottom',
      beforeShow: async () => {
        const shareButton = document.querySelector('#profile-share');
        if (shareButton) {
          (shareButton as HTMLElement).click();
        }
      },
    },
    PROFILE_LINK_CONTAINER: {
      id: 'profile-link-container',
      title: 'profileLink',
      text: 'hereYouCanCopyYourProfileLink',
      position: 'bottom',
    },
    COPY_LINK: {
      id: 'copy-link',
      title: 'copyLink',
      text: 'toCopyYourProfileLinkJustClickHere',
      position: 'bottom',
    },
    CLOSE_SHARE_MODAL_BUTTON: {
      id: 'close-share-modal-button',
      title: 'close',
      text: 'toCloseClickHere',
      position: 'bottom',
    },
    PAY_BUTTON: {
      id: 'pay-button',
      title: 'pay',
      text: 'clickHereToRegisterAPaymentIfYourWalletsAreConfigured',
      position: 'bottom',
      beforeShow: async () => {
        const element = document.querySelector('#close-share-modal-button');
        if (element) {
          (element as HTMLElement).click();
        }
      },
    },
    PAYMENT_METHODS_SECTION: {
      id: 'payment-methods-section',
      title: 'paymentMethods',
      text: 'hereYouCheckActiveAssetsForPayment',
      position: 'bottom',
    },
    PAYMENT_METHODS_SECTION_TITLE: {
      title: 'endOfProfileTour',
      text: 'thisIsTheEndOfProfileTour',
    },
  },

  // General settings page tours
  SETTINGS: {
     OVERVIEW_NO_DOMAIN: {
      WELCOME_MESSAGE: {
        id: 'settings-page-navigation',
        title: 'welcomeToSettingsTabsTour',
        text: 'letUsShowYouAround',
        position: 'bottom',
      },
      GENERAL_TAB: {
        id: 'settings-tab-general',
        title: 'welcomeToTheAccountSection',
        text: 'welcomeToNickyTour',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-general');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      CONNECTIONS_TAB: {
        id: 'settings-tab-connections',
        title: 'welcomeToTheConnectionsSection',
        text: 'nickyMayRetrieveYourWalletAddressesFromSomeExchanges',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-connections');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      ROUTING_TAB: {
        id: 'settings-tab-routing',
        title: 'welcomeToTheRoutingSection',
        text: 'thisIsWhereYouDefineTheWalletAddresses',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-routing');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      PAYMENT_CONVERSATIONS_TAB: {
        id: 'settings-tab-payment-conversions',
        title: 'welcomeToThePaymentConversionsSection',
        text: 'nickyCanAutomaticallyConvertCryptoIntoCurrency',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-payment-conversions');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      BANK_WITHDRAWAL_TAB: {
        id: 'settings-tab-bank-withdrawal',
        title: 'welcomeToTheBankWithdrawalsSection',
        text: 'nickyCanAutomaticallyWithdrawFundsIntoYourBankAccount',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-bank-withdrawal');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      SECURITY_TAB: {
        id: 'settings-tab-security',
        title: 'welcomeToTheSecuritySection',
        text: 'inCaseYouHaveALocalAccount',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-security');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      MORE_INFO: {
        id: 'settings-page-navigation',
        title: '',
        text: 'lookForTheBookIconForMoreInfo',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-security');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      END_OF_TOUR: {
        id: 'end-of-tour',
        title: 'welcomeToTheBankEndOfTour',
        text: 'weHopeYouEnjoyUsingNicky!',
        position: 'bottom',beforeShow: async () => {
          const element = document.querySelector('#settings-tab-general');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
    },
    OVERVIEW: {
      WELCOME_MESSAGE: {
        id: 'settings-page-navigation',
        title: 'welcomeToSettingsTabsTour',
        text: 'letUsShowYouAround',
        position: 'bottom',
      },
      GENERAL_TAB: {
        id: 'settings-tab-general',
        title: 'welcomeToTheAccountSection',
        text: 'welcomeToNickyTour',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-general');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      CONNECTIONS_TAB: {
        id: 'settings-tab-connections',
        title: 'welcomeToTheConnectionsSection',
        text: 'nickyMayRetrieveYourWalletAddressesFromSomeExchanges',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-connections');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      ROUTING_TAB: {
        id: 'settings-tab-routing',
        title: 'welcomeToTheRoutingSection',
        text: 'thisIsWhereYouDefineTheWalletAddresses',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-routing');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      DO_YOU_OWN_YOUR_DOMAIN: {
        id: 'settings-tab-domain',
        title: 'welcomeToTheDomainSection',
        text: 'doYouOwnYourDomain',
        position: 'bottom',
      },
      PAYMENT_CONVERSATIONS_TAB: {
        id: 'settings-tab-payment-conversions',
        title: 'welcomeToThePaymentConversionsSection',
        text: 'nickyCanAutomaticallyConvertCryptoIntoCurrency',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-payment-conversions');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      BANK_WITHDRAWAL_TAB: {
        id: 'settings-tab-bank-withdrawal',
        title: 'welcomeToTheBankWithdrawalsSection',
        text: 'nickyCanAutomaticallyWithdrawFundsIntoYourBankAccount',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-bank-withdrawal');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      SECURITY_TAB: {
        id: 'settings-tab-security',
        title: 'welcomeToTheSecuritySection',
        text: 'inCaseYouHaveALocalAccount',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-security');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      MORE_INFO: {
        id: 'settings-page-navigation',
        title: '',
        text: 'lookForTheBookIconForMoreInfo',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#settings-tab-security');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      END_OF_TOUR: {
        id: 'end-of-tour',
        title: 'welcomeToTheBankEndOfTour',
        text: 'weHopeYouEnjoyUsingNicky!',
        position: 'bottom',beforeShow: async () => {
          const element = document.querySelector('#settings-tab-general');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
    },
    GENERAL: {
      WELCOME_MESSAGE: {
        id: 'general-settings',
        title: 'welcomeToTheAccountSection',
        text: 'hereYouConfigureAllYourPersonalInformation',
        position: 'bottom',
      },
      GENERAL_FORM: {
        id: 'general-form',
        title: 'generalForm',
        text: 'hereYouCanViewOrEnterYourPersonalData',
        position: 'bottom',
      },
      SHORT_ID: {
        id: 'settings-general-short-id',
        title: 'shortId',
        text: 'thisIdIsYourUniqueIdentificationOnNicky',
        position: 'bottom',
      },
      SAVE_BUTTON: {
        id: 'settings-header-save',
        title: 'save',
        text: 'yourPublicProfileWillBeCreatedBasedOnThem',
        position: 'bottom',
      },
    },
    CONNECTIONS: {
      WELCOME_MESSAGE: {
        id: 'connections-welcome',
        title: 'welcomeToTheConnectionsSection',
        text: 'hereYouConfigureAllYourConnections',
        position: 'bottom',
      },
      ADD_CONNECTION_BUTTON: {
        id: 'add-connection-button',
        title: '',
        text: 'letsStart',
        position: 'bottom',
      },
      PROVIDER_SELECTION: {
        id: 'provider-selection',
        title: 'selectProvider',
        text: 'youMayAddAsManyConnections',
        position: 'bottom',
        beforeShow: async () => {
          const element = document.querySelector('#add-connection-button');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      API_KEY: {
        id: 'api-key-input',
        title: 'apiKey',
        text: 'theApiKeyAndSecretWillAllow',
        position: 'bottom',
      },
      API_PERMISSIONS: {
        id: 'api-key-input',
        title: 'apiPermissions',
        text: 'youCanAlsoAllowNickyToHandleSending',
        position: 'bottom',
      },
      PROVIDER_DOCS: {
        id: 'api-permissions',
        title: 'providerDocumentation',
        text: 'checkTheExchangeDocumentation',
        position: 'bottom',
      },
      PROVIDER_PERMISSIONS: {
        id: 'api-permissions',
        title: 'providerDocumentation',
        text: 'toSetupWithdrawals',
        position: 'bottom',
      },
      PROVIDER_DOCS_PERMISSIONS: {
        id: 'api-permissions',
        title: 'providerDocumentation',
        text: 'forUseAPI',
        position: 'bottom',
      },
      CONNECTION_NAME: {
        id: 'connection-name',
        title: 'connectionName',
        text: 'theNameIsCompletelyOptional',
        position: 'bottom',
      },
      FINISH_CONNECTION: {
        id: 'finish-connection',
        title: 'completeConnection',
        text: 'whenClickingOnAddConnection',
        position: 'bottom',
      },
    },
    ROUTING: {
      WELCOME_MESSAGE: {
        id: 'routing',
        title: 'welcomeToTheRoutingSection',
        text: 'thisIsWhereYouConfigureTheRoutes',
        position: 'bottom',
      },
      WELCOME_MESSAGE_SECOND: {
        id: 'routing',
        title: 'whatIsARoute',
        text: 'inNickyaRouteIsAConfiguration',
        position: 'bottom',
      },
      EDIT_ROUTES: {
        id: 'edit-routes-button',
        title: 'editRoutes',
        text: 'clickThisButtonToAddaNewWallet',
        position: 'bottom',
      },
      WALLET_PROVIDER: {
        id: 'wallet-provider-selection',
        title: 'selectWalletProvider',
        text: 'clickToAddARouting',
        position: 'top',
        beforeShow: async () => {
          const element = document.querySelector('#edit-routes-button');
          if (element) {
            (element as HTMLElement).click();
          }
        },
      },
      WALLET_SETUP: {
        id: 'wallet-provider-dropdown',
        title: 'walletSetup',
        text: 'eachAssetIsListedWithItsWalletProvider',
        position: 'bottom',
      },
      CANCEL_CHANGES: {
        id: 'cancel-wallet-button',
        title: 'cancelChanges',
        text: 'ifYouDoNotWalletToDoThisNow',
        position: 'bottom',
      },
      SAVE_CHANGES: {
        id: 'save-wallet-button',
        title: 'saveChanges',
        text: 'readyYourWalletHasBeenAdded',
        position: 'bottom',
      },
    },
    PAYMENT_CONVERSATIONS: {
      WELCOME_MESSAGE: {
        id: 'payment-conversations',
        title: 'welcomeToThePaymentConversionsSection',
        text: 'paymentConversionsReferToTheProcess',
        position: 'bottom',
      },
      ASSETS_SELECTION: {
        id: 'payment-conversions-assets',
        title: 'assetsSelection',
        text: 'inThisSectionYouEnable',
        position: 'bottom',
      },
      CURRENCY_TOGGLE: {
        id: 'currency-toggle-BRL',
        title: 'currencyToggle',
        text: 'youCanChooseItAsAPaymentAsset',
        position: 'bottom',
      },
      CURRENCY_TOGGLE_TOGGLE: {
        id: 'currency-toggle-BRL-toggle',
        title: 'currencyToggle',
        text: 'byClickingTheOnOffButton',
        position: 'bottom',
      },
    },
    BANK_WITHDRAWAL: {
      BANK_WITHDRAWALS: {
        id: 'bank-withdrawals',
        title: 'whatIsAGlobalWithdrawal',
        text: 'nickyCanTakeCareOfYourCripto',
        position: 'bottom',
      },
      GLOBAL_WITHDRAWAL_THRESHOLD: {
        id: 'bank-withdrawals-global-withdrawal-threshold',
        title: 'globalWithdrawalThreshold',
        text: 'hereYouShouldSetupTheCriteria',
        position: 'bottom',
      },
      CURRENCY_ROW: {
        id: 'bank-withdrawals-global-withdrawal-threshold-currency',
        title: 'currency',
        text: 'thisIsTheTargetCurrency',
        position: 'bottom',
      },
      INPUT: {
        id: 'bank-withdrawals-global-withdrawal-threshold-input',
        title: 'input',
        text: 'thisIsTheMinimumValueForNicky',
        position: 'bottom',
      },
      ENABLED: {
        id: 'bank-withdrawals-global-withdrawal-threshold-enabled',
        title: 'enabled',
        text: 'ifDisabled',
        position: 'bottom',
      },
      BANK_ACCOUNT_CONFIGURATION: {
        id: 'bank-account-configuration',
        title: 'bankAccountConfiguration',
        text: 'thisIsWhereYouSetupYourBankAccounts',
        position: 'bottom',
      },
      ADD_CONFIGURATION_BUTTON: {
        id: 'bank-withdrawals-add-configuration-button',
        title: 'addConfiguration',
        text: 'justClickTheButtonToAddANewWithdrawal',
        position: 'bottom',
      },
    },
    SECURITY: {
      CHANGE_PASSWORD_BUTTON: {
        id: 'change-password-button',
        title: 'changePassword',
        text: 'byClickingThisButtonYouChangePassword',
        position: 'bottom',
      },
      DELETE_ACCOUNT_BUTTON: {
        id: 'delete-account-button',
        title: 'deleteAccount',
        text: 'byClickingThisButtonYouPermanentlyDelete',
        position: 'bottom',
      },
    },



  },
} as const;
