import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { Contact } from '../components/models/contact.model';

import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { pipe, switchMap, forkJoin, of, EMPTY } from 'rxjs';
import { FavoritesService } from '../services/favorites.service';

import { UserService } from '../services/user.service';
import { map, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

interface ContactResponse {
  total: number;
  data: any[];
}

type ContactsStoreState = {
  loaded: boolean;
  total: number;
  pageSize: number;
  pageIndex: number;
  searchQuery: string; 
  filters: {
    emails: string[];
    filterForm?: any;
    mustOrderByDescending: boolean;
  };
};

const initialState: ContactsStoreState = {
  loaded: false,
  total: 0,
  pageSize: window.innerWidth >= 768 ? 10 : 5,
  pageIndex: 0,
  searchQuery: '',
  filters: {
    emails: [],
    filterForm: {},
    mustOrderByDescending: true,
  },
};

export const ContactsStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<Contact>(),
  withState(() => {
    return {
      ...initialState
    }
  }),
  withComputed((store) => ({
    totalPages: computed(() => Math.ceil(store.total() / store.pageSize())),
    filtersAndPageSize: computed(() => ({
      ...store.filters(),
      pageSize: store.pageSize(),
      pageIndex: store.pageIndex(),
      searchQuery: store.searchQuery(),
    })),
    calculatePaginationRange: computed(() => {
      const start = store.pageIndex() * store.pageSize() + 1;
      const end = Math.min((store.pageIndex() + 1) * store.pageSize(), store.entities().length);
      return `${start} - ${end}`;
    }),
  })),
  withMethods(
    (
      store,
      favoritesService = inject(FavoritesService),
      toastr = inject(ToastrService),
      translate = inject(TranslateService),
      userService = inject(UserService),
    ) => {
      const loadContacts = rxMethod<void>(
        pipe(
          switchMap(() =>
            favoritesService.getUserFavorites(store.filtersAndPageSize()).pipe(
              map(response => response as ContactResponse),
              switchMap((response) => {
                patchState(store, { total: response.total });

                const contactsWithId = response.data.map((contact: any) => ({
                  ...contact,
                  id: contact.userId,
                }));

                if (contactsWithId.length === 0) {
                  patchState(store, setAllEntities([] as Contact[]));
                  patchState(store, { loaded: true });
                  return EMPTY;
                }

                const contactsWithAssetsAndImages = contactsWithId.map((contact) =>
                  forkJoin({
                    profileImageUrl: userService.getUserImageById(contact.id).pipe(
                      map((imageBlob) => URL.createObjectURL(imageBlob as Blob)),
                      catchError(() => of(undefined)),
                    ),
                    acceptedAssets: contact.hasRoutes 
                      ? userService.getReceiverConnections(contact.id).pipe(
                          map((assets: any[]) => {
                            const uniqueAssets: any[] = [];
                            assets.forEach((asset) => {
                              if (!uniqueAssets.some((existing) => existing.assetTicker === asset.assetTicker)) {
                                uniqueAssets.push(asset);
                              }
                            });
                            // TODO: MOVE INTO SINGE STORE POINT REF LOCSORT
                            return uniqueAssets.sort((a, b) => 
                              a.assetTicker.localeCompare(b.assetTicker)
                            );
                          }),
                          catchError(() => of([])),
                        )
                      : of([]),
                  }).pipe(
                    map(({ profileImageUrl, acceptedAssets }) => ({
                      ...contact,
                      profileImageUrl,
                      acceptedAssets,
                    })),
                  ),
                );

                return forkJoin(contactsWithAssetsAndImages);
              }),
              tapResponse({
                next: (updatedContacts: Contact[]) => {
                  patchState(store, setAllEntities(updatedContacts));
                  patchState(store, { loaded: true });
                },
                error: (error: { message: string }) => {
                  console.error('Error loading contacts:', error);
                  patchState(store, setAllEntities([] as Contact[]));
                  patchState(store, { loaded: true });
                },
              }),
            ),
          ),
        ),
      );

      const top3Contacts = computed(() => {
        const allContacts = store.entities();
        // For now, just take first 3 contacts
        // TODO: Replace with actual top contacts service call
        return allContacts?.slice(0, 3);
      });

      const searchContacts = rxMethod<{query: string, pageSize: number, pageIndex: number}>(
        pipe(
          debounceTime(300),
          distinctUntilChanged(),
          switchMap(({query, pageSize, pageIndex}: {query: string, pageSize: number, pageIndex: number}) => {
            patchState(store, { 
              loaded: false, 
              searchQuery: query,
              pageIndex: store.pageIndex()
            });
            
            return (query ? favoritesService.searchFavorites(query, pageSize, pageIndex) 
              : favoritesService.getUserFavorites(store.filtersAndPageSize())).pipe(
              map(response => response as ContactResponse),
              switchMap((response) => {
                patchState(store, { total: response.total });

                const contactsWithId = response.data.map((contact: any) => ({
                  ...contact,
                  id: contact.userId,
                }));

                if (contactsWithId.length === 0) {
                  patchState(store, setAllEntities([] as Contact[]));
                  patchState(store, { loaded: true });
                  return EMPTY;
                }

                const contactsWithAssetsAndImages = contactsWithId.map((contact) =>
                  forkJoin({
                    profileImageUrl: userService.getUserImageById(contact.id).pipe(
                      map((imageBlob) => URL.createObjectURL(imageBlob as Blob)),
                      catchError(() => of(undefined)),
                    ),
                    acceptedAssets: userService.getReceiverConnections(contact.id).pipe(
                      map((assets: any[]) => {
                        const uniqueAssets: any[] = [];
                        assets.forEach((asset) => {
                          if (!uniqueAssets.some((existing) => existing.assetTicker === asset.assetTicker)) {
                            uniqueAssets.push(asset);
                          }
                        });
                        return uniqueAssets.sort((a, b) => 
                          a.assetTicker.localeCompare(b.assetTicker)
                        );
                      }),
                      catchError(() => of([])),
                    ),
                  }).pipe(
                    map(({ profileImageUrl, acceptedAssets }) => ({
                      ...contact,
                      profileImageUrl,
                      acceptedAssets,
                    })),
                  ),
                );

                return forkJoin(contactsWithAssetsAndImages);
              }),
              tapResponse({
                next: (updatedContacts: Contact[]) => {
                  patchState(store, setAllEntities(updatedContacts));
                  patchState(store, { loaded: true });
                },
                error: (error) => {
                  console.error('Error searching contacts:', error);
                  patchState(store, setAllEntities([] as Contact[]));
                  patchState(store, { loaded: true });
                },
              }),
            );
          }),
        ),
      );

      const deleteContact = rxMethod<string>(
        pipe(
          switchMap((id: string) => favoritesService.deleteFavorite(id)),
          tapResponse({
            next: () => {
              patchState(store, initialState);
              loadContacts();
            },
            error: (error: { message: string }) => {
              toastr.error(translate.instant('deleteContactFailed'));
            },
          }),
        ),
      );

    const previousPage = () => {
      if (store.pageIndex() > 0) {
        const newPageIndex = store.pageIndex() - 1;
        patchState(store, {
          pageIndex: newPageIndex,
        });
        
        if (store.searchQuery()) {
          searchContacts({
            query: store.searchQuery(), 
            pageSize: store.pageSize(), 
            pageIndex: newPageIndex
          });
        } else {
          loadContacts();
        }
      }
    };

    const nextPage = () => {
      if (store.pageIndex() < store.totalPages() - 1) {
        const newPageIndex = store.pageIndex() + 1;
        patchState(store, {
          pageIndex: newPageIndex,
        });
        
        if (store.searchQuery()) {
          searchContacts({
            query: store.searchQuery(), 
            pageSize: store.pageSize(), 
            pageIndex: newPageIndex
          });
        } else {
          loadContacts();
        }
      }
    };

    const changeItemsPerPage = (pageSize: number) => {
      patchState(store, {
        pageIndex: 0,
        pageSize,
      });
      // Use appropriate method based on search state
      if (store.searchQuery()) {
          searchContacts({query: store.searchQuery(), pageSize, pageIndex: 0});
      } else {
        loadContacts();
      }
    };
      // const exportData = rxMethod<{type: ExportType, customBody?: Partial<ExportRequestBody>}>(
      //   pipe(
      //     switchMap(({type, customBody}) => {
      //       return filterService.exportData(type, {
      //         ...customBody,
      //       }).pipe(
      //         tapResponse({
      //           next: (response) => {
      //             if (response.status === 200) {
      //               toastr.success(translate.instant('exportSuccess'));
      //             } else {
      //               toastr.error(translate.instant('exportFailed'));
      //             }
      //           },
      //           error: () => {
      //             toastr.error(translate.instant('exportFailed'));
      //           },
      //         })
      //       );
      //     })
      //   )
      // );]

      return {
        nextPage,
        previousPage,
        changeItemsPerPage,
        searchContacts,
        loadContacts,
        top3Contacts,
        deleteContact,
      };
    },
  ),
);
