import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { SelectEntityId, setAllEntities, withEntities } from '@ngrx/signals/entities';

import { Asset } from "../components/models/asset.model";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { computed, inject } from "@angular/core";
import { tapResponse } from "@ngrx/operators";
import { pipe, switchMap, tap } from "rxjs";
import { UserService } from "../services/user.service";
import { IBalance } from "../components/models/balance.model";
import { compareAssetIds, formatAssetId } from "../utils/asset-id-utils";

type BalancesStoreState = {
    loaded: boolean;
};

const initialState: BalancesStoreState = {
 loaded: false
};

const selectId: SelectEntityId<IBalance> = balance =>
  balance.blockchainAsset.id;

export const BalancesStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<IBalance>(),
  withState(initialState),
  withComputed(({entities}) => {

    const totalBalances = computed(() => {
      return entities().filter(balance => balance.amountNative > 0).sort((a, b) => {
        a = formatAssetId(a);
        b = formatAssetId(b);
        return compareAssetIds(a, b);
      });
    });

    return {
      totalBalances
    }
    }),
  withMethods((store, userService = inject(UserService)) => {
    const loadBalances = rxMethod<void>(
    pipe(
          switchMap(() => {
            return userService.getBalances().pipe(
              tapResponse({
                next: ({total}) => {
                  patchState(store, setAllEntities(total, {selectId}));
                  patchState(store, {loaded: true});
                },
                error: (error: { message: string }) => {
                  // todo: handle error
                  console.log(error);
                },
              })
            );
          })
        )
    );



    return {
      loadBalances,
    }
  }),
);
