import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { QuoteModel } from '../components/models/quote.model';
import { Wallet } from '../components/models/wallet.model';
import { formatEthOrMaticAddress } from '../utils/format-eth-matic-address.utils';
import { createHttpOptions } from '../utils/http-utils';
import { Asset } from '../components/models/asset.model';
import { CreatePaymentReportDto } from '../dtos/create-payment-report.dto';

@Injectable({
  providedIn: 'root',
})

export class PaymentService {
  private http = inject(HttpClient);

  getUserWallets(targetNick: string, selectedAsset: string) {
    return this.http.get<Wallet>(
      `${environment.api}Address/deposit-address?assetId=${selectedAsset}&nick=${targetNick}`,
      createHttpOptions('text/plain'),
    );
  }

  getPrice(currency: string, targetCurrency: string) {
    const body = {
      amount: 1,
      fromBlockchainId: currency,
      toBlockchainId: targetCurrency,
    };
    return this.http.post<QuoteModel>(`${environment.api}ConversionRate/get-price`, body, createHttpOptions());
  }

  getQuotes(amount: number, currency: string, targetCurrency: string) {
    const body = {
      amount: amount,
      fromBlockchainId: currency,
      toBlockchainId: targetCurrency,
    };
    return this.http.post(`${environment.api}ConversionRate/get-quote`, body, createHttpOptions());
  }

  getTransactions(assetId: string, address: string, dateTime: string) {
    return this.http.get(
      `${environment.api}Blockchain/transactions/${assetId}/${address}?startTime=${dateTime}`,
      createHttpOptions('text/plain'),
    );
  }

  doTransaction(paymentReport: CreatePaymentReportDto) {
    const body = paymentReport;

    return this.http.post(`${environment.api}PaymentReport/create`, body, createHttpOptions());
  }

  private getAssetId(assetId: string): string {
    switch (assetId) {
      case 'USD':
        return 'USD.USD';
      case 'EUR':
        return 'EUR.EUR';
      default:
        return assetId;
    }
  }

  private getAnonymousUserInfo(user: any) {
    return { anonUserName: user.name, anonUserEmail: user.email, anonUserCompany: user.company };
  }

  private formatEthOrMaticTransactionId(transactionId: string, blockchainAssetId: string) {
    const isEthereumOrMatic = blockchainAssetId.split('.')[0] === 'ETH' || blockchainAssetId.split('.')[0] === 'MATIC';
    return isEthereumOrMatic ? formatEthOrMaticAddress(transactionId) : transactionId;
  }

  getAllAssets() {
    return this.http.post<Asset[]>(`${environment.api}BlockchainAsset/all`, {}, createHttpOptions('text/plain'));
  }
}
