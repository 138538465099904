import { Directive, HostListener, inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[toggleMatTooltip]',
  standalone: true,
})
export class toggleMatTooltipDirective {
  private matTooltip = inject(MatTooltip);

  @HostListener('click')
  onClick() {
    this.matTooltip.toggle();
  }
}
