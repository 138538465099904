import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { CryptoConnectionDTO } from '../dtos/cryto-connection.dto';
import { ProfileDto } from '../dtos/user-profile.dto';
import { createHttpOptions } from '../utils/http-utils';
import { tap, catchError, EMPTY, firstValueFrom, Observable } from 'rxjs';
import { IBalance } from '../components/models/balance.model';
import { Asset } from '../components/models/asset.model';
import { CryptoConnection, CryptoConnectionInfo, UserAssetConnection } from '../stores/user-connections.store';
import auth0 from 'auth0-js';
import { Domain } from '../stores/auth.store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrFactoryService } from './toastr-factory.service';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
    responseType: 'token id_token',
    audience: environment.auth0Domain + '/api/v2/',
    scope: 'openid profile email update:current_user_metadata',
    redirectUri: window.location.origin,
  });

  protected route = inject(Router);
  protected http = inject(HttpClient);
  protected toastrFactory = inject(ToastrFactoryService);
  protected translate = inject(TranslateService);


  getUser() {
    return this.http.get<ProfileDto>(environment.api + 'User/profile', createHttpOptions());
  }

  updateProfilePicture(image: any) {
    const formData = new FormData();
    formData.append('file', image);
    return this.http.post(environment.api + 'User/set-profile-picture', formData, {
      responseType: 'text',
    });
  }

  getProfilePicture() {
    return this.http.get(environment.api + 'User/get-profile-picture', {
      ...createHttpOptions('image/*'),
      responseType: 'blob' as 'json',
    });
  }

  getDomain(): Observable<Domain[]> {
    return this.http.get<Domain[]>(environment.api + 'Domain/all', createHttpOptions());
  }

  addDomain(domain: string) {
    const body = JSON.stringify(domain);
    return this.http.post(environment.api + 'Domain/add', body, createHttpOptions('*/*', 'application/json'));
  }

  public editGeneralUserInfo(userProfile: ProfileDto) {
    return this.http.post(
      environment.api + 'User/profile',
      userProfile,
      createHttpOptions(undefined, 'application/json'),
    );
  }

  getUserImageById(userId: string) {
    return this.http.get(environment.api + 'User/get-public-profile-picture?userId=' + userId, {
      ...createHttpOptions('image/jpeg'),
      responseType: 'blob' as 'json',
    });
  }

  public checkDNS(domain: any) {
    const body = JSON.stringify(domain);
    return this.http.post(environment.api + 'Domain/force-check', body, createHttpOptions('*/*', 'application/json'));
  }

  getCryptoConnections(): Observable<CryptoConnection[]> {
    return this.http.get<CryptoConnection[]>(`${environment.api}CryptoConnection/all`);
  }

  getReceiverConnections(userId: string): Observable<Asset[]> {
    return this.http.get<Asset[]>(
      environment.api + 'UserAssetConnection/all-assets-for-userid?userId=' + userId,
      createHttpOptions(),
    );
  }

  checkExchangeConnection(exchangeId: string) {
    return this.http.post(
      environment.api + 'CryptoConnection/check-if-valid?id=' + exchangeId,
      {},
      createHttpOptions(undefined, 'application/json'),
    );
  }

  deleteUserAssetConnection(connectionId: string) {
    return this.http.delete(
      environment.api + 'UserAssetConnection?id=' + connectionId,
      createHttpOptions(undefined, 'application/json'),
    );
  }


  removeMultipleUserAssetConnection(userAssetConnectionIdList: string[]) {
    return this.http.delete(
      environment.api + 'UserAssetConnection/delete-multiple',
      { body: userAssetConnectionIdList }
    );
  }


  getAllUserAssetConnections(): Observable<UserAssetConnection[]> {
    return this.http.get<UserAssetConnection[]>(`${environment.api}UserAssetConnection/all`);
  }

  public addCryptoConnection(connectionDto: CryptoConnectionDTO) {
    return this.http.post(
      environment.api + 'CryptoConnection/add',
      connectionDto,
      createHttpOptions(undefined, 'application/json'),
    );
  }

  public addMultipleCryptoConnection(connectionList: CryptoConnectionDTO[]) {
    return this.http.post(
      environment.api + 'CryptoConnection/add-multiple',
      connectionList,
      createHttpOptions(undefined, 'application/json'),
    );
  }

  public getAcceptedAssets(): Observable<Asset[]> {
    return this.http.get<Asset[]>(environment.api + 'AcceptedAsset/get', createHttpOptions());
  }

  getCryptoConnectionAvailableFeaturesAndAssets(): Observable<CryptoConnectionInfo[]> {
    return this.http.get<CryptoConnectionInfo[]>(`${environment.api}CryptoConnection/available`);
  }

  public getAcceptedAssetsById(userId: any): Observable<Asset[]> {
    return this.http.get<Asset[]>(environment.api + 'AcceptedAsset/get-for-user?userId=' + userId, createHttpOptions());
  }

  public setAcceptedAssets(assets: string[], accessToken?: string) {
    return this.http.post(
      environment.api + 'AcceptedAsset/set',
      assets,
      createHttpOptions(undefined, 'application/json', accessToken),
    );
  }

  public associateMultipleConnections(assoaciationList:any) {
    return this.http.post(
      environment.api + 'UserAssetConnection/add-multiple',
      assoaciationList,
      createHttpOptions(undefined, 'application/json'),
    );
  }

  public associateConection(blockchainAssetId: String, cryptoConnectionId: string) {
    const body = {
      blockchainAssetId: blockchainAssetId,
      cryptoConnectionId: cryptoConnectionId,
    };
    return this.http.post(environment.api + 'UserAssetConnection/add', body, createHttpOptions());
  }

  public removeCryptoConnection(connectionId: string) {
    return this.http.delete(
      environment.api + 'CryptoConnection?id=' + connectionId,
      createHttpOptions(undefined, 'application/json'),
    );
  }


  removeMultipleCryptoConnections(connectionIdList: string[]) {
    return this.http.delete(
      environment.api + 'CryptoConnection/delete-multiple',
      { body: connectionIdList }
    );
  }


  fullyLogout(url: string) {
    return this.http.get(environment.auth0Domain + `/v2/logout?returnTo=${url}&client_id=${environment.auth0ID}`);
  }

  public passwordlessAuthentication(email: any) {

    this.userIsValidated(email)
      .pipe(
        tap((response: any) => {
          if (response == false) {
            this.toastrFactory.error(
              this.translate.instant('weDidNotFindAnyValidUserWithThisEmail'),
              '',
              {
                timeOut: 10000,
              },
            );
            return;
          }
          if (response == true) {

            this.webAuth.passwordlessStart(
              {
                connection: 'email',
                send: 'link',
                email: email,

                authParams: {
                  redirectUri: window.location.origin + '/mailLink',
                },
              },
              (err, authResult) => {
                if (authResult) {
                  this.toastrFactory.success(
                    this.translate.instant('weHaveSentALoginLinkToTheGivenEmail'),
                  );
                  return;
                } else {
                  console.log(err);
                  if (err?.code == 'bad.email') {
                    this.toastrFactory.error(this.translate.instant('invalidEmailCheckIfTheEmailIsCorrect'));
                    return;
                  }
                  this.toastrFactory.error(this.translate.instant('somethingWentWrongSendingYourAMagicLink'));
                  return;
                }
              },
            );

            setTimeout(() => {
              this.route.navigate(['/overview']);
            }, 2000);
          }
        }),
        catchError((error: any) => {

          console.log(error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public deleteUser() {
    return this.http.delete(environment.api + 'User/delete');
  }

  public setUserPreferedLanguage(ISOlanguage: string, accessToken?: string) {
    const body = `"${ISOlanguage}"`;
    return this.http.post(
      environment.api + 'User/set-language',
      body,
      createHttpOptions('text/plain', 'application/json', accessToken),
    );
  }

  public userIsValidated(email: any) {
    return this.http.post(environment.api + 'User/isValidated?email=' + email, createHttpOptions());
  }

  public userIsValidatedWithDetails(email: any) {
    return this.http.post(environment.api + 'User/isValidatedWithDetails?email=' + email, createHttpOptions());
  }

  public getBalances() {
    return this.http.post<{ total: IBalance[] }>(
      environment.api + 'Balance/all',
      '',
      createHttpOptions('text/plain', 'application/json'),
    );
  }

  public async callReservedShortid(shortId: string, email: string) {
    if (!email || !shortId) {
      return;
    }

    return firstValueFrom(
      this.http.post(environment.api + 'ReservedShortId/preregister', {
        email: email,
        shortId: shortId,
      }),
    );
  }
}
