import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, switchMap, take, tap, finalize, EMPTY, catchError, map } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { AuthService } from '@auth0/auth0-angular';
import { ProfileDto } from '../dtos/user-profile.dto';
import { UserService } from '../services/user.service';
import { Auth0UserProfile } from '../components/models/user.mode';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BalancesStore } from './balances.store';
import { ReportsStore } from './reports.store';
import { ContactsStore } from './contacts.store';
import { ToastrFactoryService } from '../services/toastr-factory.service';
import { environment, systemLanguages } from 'src/environments/environment';
import { LoadingService } from '../services/loading.service';
import { ModalsService } from '../services/modals.service';
import { TermsModalComponent } from 'src/app/components/modals/terms-modal/terms-modal.component';
import { UTMDto } from '../dtos/utm.dto';
import countries from 'src/assets/config/country-list';
import { DomainOwnerModalComponent } from '../components/modals/domain-owner-modal/domain-owner-modal.component';
import { commonDomainsConfig } from '../config/domains.config';
import { TourService } from '../services/tour/tour.service';

export interface Domain {
  name?: string;
  dnsName?: string;
  validationKey?: string;
  isValid: boolean;
  isOwnedByOtherUser: boolean;
  lastValidDate?: Date;
  lastCheckDate?: Date;
  nextCheckDate?: Date;
  ownerEmail?: string;
  description?: string;
  status?: string;
}

type AuthStoreState = {
  user: Auth0UserProfile | null;
  authenticated: boolean;
  profile: ProfileDto | null;
  domains: Domain[];
  profilePicture: SafeUrl | null;
  alreadyChecked: boolean;
};

const initialState: AuthStoreState = {
  user: null,
  authenticated: false,
  profile: null,
  domains: [],
  profilePicture: null,
  alreadyChecked: false,
};

export const AuthStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withComputed(({ user, profile, domains }) => {
    const userHasProfile = computed(() => {
      return profile() !== null;
    });

    const isGoogleAuth = computed(() => {
      return user()?.googleAuth;
    });

    const hasCommonDomain = computed(() => {
      return commonDomainsConfig.includes(profile()?.email?.split('@')[1] || '');
    });

    const hasAvailableDomains = computed(() => {
      return domains().length > 0;
    });

    return {
      userHasProfile,
      isGoogleAuth,
      hasCommonDomain,
      hasAvailableDomains,
    };
  }),
  withComputed(({ authenticated, alreadyChecked }) => {
    const authenticationChecked = computed(() => authenticated);
    const isAlreadyChecked = computed(() => alreadyChecked);

    return {
      authenticationChecked,
      isAlreadyChecked,
    };
  }),
  withMethods(
    (
      store,
      authService = inject(AuthService),
      userService = inject(UserService),
      sanitizer = inject(DomSanitizer),
      translate = inject(TranslateService),
      router = inject(Router),
      balancesStore = inject(BalancesStore),
      reportsStore = inject(ReportsStore),
      contactsStore = inject(ContactsStore),
      toastrFactory = inject(ToastrFactoryService),
      loadingService = inject(LoadingService),
      modalsService = inject(ModalsService),
      tourService = inject(TourService),
    ) => {
      const currentUser = rxMethod<void>(
        pipe(
          switchMap((_) =>
            authService.isAuthenticated$.pipe(
              tap((isAuthenticated) => {
                if (isAuthenticated === false) {
                  patchState(store, {
                    authenticated: false,
                    alreadyChecked: true,
                  });
                }
              }),
              filter(Boolean),
              switchMap(() => authService.user$.pipe(take(1), filter(Boolean))),
              tapResponse({
                next: (user) => {
                  patchState(store, {
                    user: user as Auth0UserProfile,
                    profilePicture: user.picture,
                  });
                },
                error: (error) => {
                  console.error(error);
                },
              }),
            ),
          ),
          switchMap((_) =>
            userService.getUser().pipe(
              tapResponse({
                next: (profile) => {
                  translate.use(profile.language || navigator.language.toLowerCase());

                  patchState(store, {
                    profile: profile,
                  });

                  patchState(store, {
                    authenticated: true,
                    alreadyChecked: true,
                  });

                  try {
                    getProfilePicture();
                  } catch (e) {
                    console.error(e);
                  }

                  if (!profile.language) {
                    // Get browser language and check if supported
                    const browserLang = navigator.language.toLowerCase();
                    const supportedLanguage = systemLanguages.find(
                      (lang: any) =>
                        lang.language.toLowerCase() === browserLang ||
                        browserLang.startsWith(lang.language.toLowerCase()),
                    );

                    // Default to 'en' if browser language not supported
                    const userLanguage = supportedLanguage ? supportedLanguage.language : 'en';

                    updateProfileLanguage({ language: userLanguage });
                  }
                  
                  checkPrivacyPolicyStatus(profile);

                  // Initializes the balances, reports and contacts
                  // Only after the user and domains is loaded
                  balancesStore.loadBalances();
                  reportsStore.loadReports(reportsStore.filtersAndPageSize);
                  contactsStore.loadContacts();
                },
                error: function (error: unknown): void {
                  throw new Error('Function not implemented.');
                },
              }),
            ),
          ),
          switchMap((_) =>
            userService.getDomain().pipe(
              tapResponse({
                next: (domains) => {
                  patchState(store, {
                    domains: domains,
                  });
                },
                error: function (error: unknown): void {
                  throw new Error('Function not implemented.');
                },
              }),
            ),
          ),
        ),
      );

      const isUserSSO = rxMethod<ProfileDto>(
        pipe(
          switchMap(() => {
            const newUser = store.user();
            if (!newUser) return EMPTY;

            const utmInfo = getUTMInfo();
            const referrer = getReferrer();
            const userCountry = getUserCountry(newUser);

            const userInfo: ProfileDto = {
              name: newUser.name,
              publicName: newUser.name,
              websiteUrl: undefined,
              country: userCountry,
              umtCampaign: utmInfo.utmCampaign,
              umtMedium: utmInfo.utmMedium,
              umtSource: utmInfo.utmSource,
              referrer: referrer,
            };

            patchState(store, {
              profile: {
                ...store.profile(),
                ...userInfo,
              },
            });

            // Set the language in the translation service
            translate.use(userInfo.language || 'en');
            return setGeneralInfoForSocialSignup(userInfo, newUser);
          }),
        ),
      );

      function getUserCountry(newUser: Auth0UserProfile): string {
        // Default to 'US' if no country is found
        const defaultCountry = 'US';
        const userLocale = newUser['https://example.com/country'] || '';

        // Return default immediately if no userLocale
        if (!userLocale) return defaultCountry;
        const c = countries.find((country) => country.name === userLocale)?.code;

        return c || defaultCountry;
      }

      function getUTMInfo(): UTMDto {
        const utmSettingsItem = localStorage.getItem('utmSettings');
        if (utmSettingsItem) {
          try {
            return JSON.parse(utmSettingsItem);
          } catch (error) {
            console.error('Error parsing JSON from localStorage:', error);
          }
        }
        return {};
      }

      function getReferrer(): string | undefined {
        return localStorage.getItem('referrer') || undefined;
      }

      function setGeneralInfoForSocialSignup(userInfo: ProfileDto, newUser: Auth0UserProfile) {
        return userService.editGeneralUserInfo(userInfo).pipe(
          tapResponse({
            next: (profile: ProfileDto) => {
              // Update the store with the new profile
              patchState(store, {
                profile: profile,
              });
              // Set the language in the translation service
              translate.use(profile.language || 'en');
            },
            error: (error) => {
              console.error('Error setting general info:', error);
            },
          }),
          catchError(() => EMPTY),
        );
      }

      function normalizeWebsiteUrl(profile: Partial<ProfileDto>): void {
        if (!profile.websiteUrl || profile.websiteUrl.trim() === '') {
          profile.websiteUrl = 'https://';
          return;
        }
        if (!profile.websiteUrl.match(/^https?:\/\//)) {
          profile.websiteUrl = 'https://' + profile.websiteUrl;
        }
      }

      const updateProfileInfo = rxMethod<Partial<ProfileDto>>(
        pipe(
          map((profileUpdate) => {
            normalizeWebsiteUrl(profileUpdate);
            return profileUpdate;
          }),
          switchMap((profileUpdate) =>
            userService.editGeneralUserInfo(profileUpdate).pipe(
              tapResponse({
                next: (profile: ProfileDto) => {
                  patchState(store, {
                    profile: profile,
                  });

                  if (!profile.name && !profile.publicName && store.user()?.googleAuth) {
                    isUserSSO(profile);
                  }

                  toastrFactory.success(translate.instant('PROFILE.userInfoUpdatedSuccessfully'), '', {
                    timeOut: 3000,
                  });
                },
                error: (error) => {
                  toastrFactory.error(translate.instant('unknownError'), '', {
                    timeOut: 3000,
                  });
                  console.error('Error updating profile:', error);
                },
              }),
            ),
          ),
        ),
      );

      const checkPrivacyPolicyStatus = async (user: any) => {
        if (user.agreedPrivacyPolicy) {
          return;
        }
        const res = await modalsService.openModal(TermsModalComponent, { data: { user } });

        if (res) {
          const domain = user.email.split('@')[1];
          if (commonDomainsConfig.includes(domain)) {
            handleAcceptedTerms();
          } else {
            const res = await modalsService.openModal(DomainOwnerModalComponent, { data: { domain } });
            if (res) {
              userService.addDomain(domain).subscribe({
                next: () => {
                  updateDomain();
                  handleAcceptedTerms();
                },
                error: (e: any) => {
                  toastrFactory.error(translate.instant('somethingWentWrongWhileWeAreTryingToAddYourDomain'));
                  console.error('Error updating privacy policy status:', e);
                },
              });
            } else {
              handleAcceptedTerms();
            }
          }
        } else {
          logout();
        }
      };

      const handleAcceptedTerms = rxMethod<void>(
        pipe(
          switchMap((_) =>
            userService.editGeneralUserInfo({ agreedPrivacyPolicy: true }).pipe(
              tapResponse({
                next: () => {
                  updateProfileInfo({ agreedPrivacyPolicy: true });
                },
                error: (error) => {
                  console.error('Error updating privacy policy status:', error);
                },
              }),
            ),
          ),
          tap(() => {
            router.navigate(['/settings']).then(() => {
              tourService.startTour(store.hasAvailableDomains() ? 'SETTINGS.OVERVIEW' : 'SETTINGS.OVERVIEW_NO_DOMAIN');
            });
          }),
        ),
      );

      const updateProfileLanguage = rxMethod<Partial<ProfileDto>>(
        pipe(
          switchMap((profileUpdate) =>
            userService.setUserPreferedLanguage(profileUpdate.language!).pipe(
              tapResponse({
                next: (profile) => {
                  patchState(store, {
                    profile: profile,
                  });
                },
                error: (error) => {
                  console.error('Error updating profile:', error);
                },
              }),
            ),
          ),
        ),
      );

      const updateProfilePicture = rxMethod<File>(
        pipe(
          switchMap((userImg) => {
            const profile = store.profile();
            return userService.updateProfilePicture(userImg).pipe(
              switchMap(() =>
                userService.getUserImageById(profile?.id || '').pipe(
                  tap((newImg) => {
                    const safeUrl = sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(newImg as unknown as Blob));
                    patchState(store, { profilePicture: safeUrl });
                  }),
                ),
              ),
              tapResponse({
                next: () => {
                  toastrFactory.success(translate.instant('PROFILE.pictureUpdatedSuccessfully'), '', { timeOut: 3000 });
                },
                error: (error) => {
                  toastrFactory.error(translate.instant('PROFILE.errorUpdatingProfilePicture'));
                  console.error('Error updating profile picture:', error);
                },
              }),
            );
          }),
        ),
      );

      const updateDomain = rxMethod<void>(
        pipe(
          switchMap(() =>
            userService.getDomain().pipe(
              tapResponse({
                next: (domains) => {
                  patchState(store, {
                    domains: domains,
                  });
                  domains[0].isValid == true
                    ? toastrFactory.success(translate.instant('PROFILE.validatedDomain'), '', {
                        timeOut: 3000,
                      })
                    : toastrFactory.warning(translate.instant('PROFILE.yourDnsStatusStillTheSame'), '', {
                        timeOut: 3000,
                      });
                },
                error: (error) => {
                  toastrFactory.error(translate.instant('PROFILE.nickyWasUnableToValidateYourDomain'), '', {
                    timeOut: 5000,
                  });
                  console.log(error);
                },
              }),
            ),
          ),
        ),
      );

      const getProfilePicture = rxMethod<void>(
        pipe(
          switchMap(() =>
            userService.getProfilePicture().pipe(
              tapResponse({
                next: (response) => {
                  const safeUrl = sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response as Blob));
                  patchState(store, {
                    profilePicture: safeUrl,
                  });
                },
                error: (error) => {
                  console.error('Error getting profile picture:', error);
                },
              }),
            ),
          ),
        ),
      );

      const logout = rxMethod<void>(
        pipe(
          tap(() => loadingService.setLoading(true)),
          switchMap(() => authService.logout({ logoutParams: { returnTo: environment.websiteUrl } })),
          tap(() => router.navigate(['/'])),
          finalize(() => loadingService.setLoading(false)),
        ),
      );

      return {
        logout,
        getProfilePicture,
        updateDomain,
        updateProfilePicture,
        currentUser,
        updateProfileInfo,
        updateProfileLanguage,
      };
    },
  ),
);
