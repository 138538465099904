<div class="flex flex-col min-h-screen" [class.bg-[#F1F1F1]]="fullWidthMode$ | async">
  @if (isAuthenticated$ | async) {
    <app-header-private [fullWidthMode]="(fullWidthMode$ | async) ?? false"></app-header-private>
  } @else {
    <!-- Top Banner -->
    @if (shouldShowBanner()) {
      <div
        (click)="goToRegister()"
        class="relative bg-gray-500 cursor-pointer text-white px-4 py-2 flex justify-center items-center z-30 bg-[#323232]"
      >
        <span class="text-sm underline" [innerHTML]="getAuthLinkContents()"></span>
      </div>
    }

    <app-header-public [fullWidthMode]="(fullWidthMode$ | async) ?? false"></app-header-public>
  }

  <main
    class="flex-1 bg-[#F1F1F1] h-screen lg:h-[calc(100vh-65px)]"
    [class.lg:px-14]="!router.url.includes('register')"
  >
    <router-outlet></router-outlet>
  </main>

  @if (!(fullWidthMode$ | async)) {
    <app-nicky-footer></app-nicky-footer>
  }
</div>
