<div #searchContainer class="search-container">
  <!-- Desktop Search Bar -->
  <div class="relative w-full max-w-md">
    <!-- Mobile Search Icon (only show when not in search mode) -->
    @if (!isMobileSearchOpen) {
      <app-nicky-button
        size="medium"
        iconLeft="ph ph-magnifying-glass"
        variant="text"
        class="lg:hidden"
        (click)="toggleMobileSearch()"
      >
      </app-nicky-button>
    }

    <!-- Desktop Search Input -->
    <div class="hidden lg:block">
      <!-- Search Icon -->
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <i class="ph ph-magnifying-glass text-gray-400"></i>
      </div>

      <!-- Search Input -->
      <input
        type="text"
        class="w-full pl-10 pr-4 py-2 bg-gray-50 border border-gray-300 rounded-lg text-sm placeholder-gray-400 form-input-base"
        placeholder="{{ 'searchNick' | translate }}"
        (input)="onInputChange($event)"
        #searchInput
      />
    </div>

    <!-- Mobile Search Overlay -->
    @if (isMobileSearchOpen) {
      <div class="fixed top-[52px] max-h-[calc(100vh-60vh)] inset-x-0 bg-white z-50 lg:hidden rounded-b-2xl">
        <div class="flex items-center gap-3 p-4 border-b border-gray-200">
          <app-nicky-button size="nav-button" variant="text" (click)="toggleMobileSearch()">
            <i class="ph ph-x"></i>
          </app-nicky-button>

          <div class="flex-1">
            <input
              type="text"
              class="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg text-sm placeholder-gray-400 form-input-base"
              placeholder="Profile Search"
              (input)="onInputChange($event)"
              #mobileSearchInput
              autofocus
            />
          </div>
        </div>

        <!-- Mobile Search Results with proper scrolling -->
        <div class="overflow-y-auto" style="max-height: calc(40vh - 52px)">
          @if (showResults && (searchResults?.length > 0 || searchTerm)) {
            @if (searchResults?.length === 0) {
              <div class="px-4 py-3 text-sm text-gray-500">
                {{ 'weDidntFindAnyResultForYourSearch' | translate }}
              </div>
            } @else {
              @for (result of searchResults; track result.shortId) {
                <div
                  class="hover:bg-gray-50 px-4 py-2 cursor-pointer flex items-center gap-3"
                  (click)="goToProfile(result.shortId)"
                >
                  <app-user-profile-picture
                    [imageUrl]="result.profileImageUrl"
                    [name]="result.publicName || result.nick"
                    [size]="'md'"
                    [className]="'size-8 rounded-full'"
                  ></app-user-profile-picture>
                  <div class="flex-1">
                    <div class="text-sm font-medium">{{ result.publicName }}</div>
                    <div class="text-xs text-gray-500">{{ result.nick }}</div>
                  </div>
                  <!-- @if (result.domains?.length > 0) {
                    <i class="ph ph-check-circle text-green-500 w-6 h-6"></i>
                  } -->
                </div>
              }
            }
          }
        </div>
      </div>
    }

    <!-- Desktop Search Results Dropdown -->
    @if (!isMobileSearchOpen && showResults && (searchResults?.length > 0 || searchTerm)) {
      <div
        class="absolute mt-1 w-full bg-white rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-y-auto overflow-x-hidden z-50"
      >
        @if (searchResults?.length === 0) {
          <div class="px-4 py-3 text-sm text-gray-500">
            {{ 'weDidntFindAnyResultForYourSearch' | translate }}
          </div>
        } @else {
          @for (result of searchResults; track result.shortId) {
            <div
              class="hover:bg-gray-50 px-4 py-2 cursor-pointer flex items-center gap-3 min-w-0"
              (click)="goToProfile(result.shortId)"
            >
              <app-user-profile-picture
                [imageUrl]="result.profileImageUrl"
                [name]="result.publicName || result.nick"
                [size]="'md'"
                [className]="'size-8 rounded-full shrink-0'"
              ></app-user-profile-picture>
              <div class="flex-1 min-w-0">
                <div class="text-sm font-medium truncate">{{ result.publicName }}</div>
                <div class="text-xs text-gray-500 truncate">{{ result.nick }}</div>
              </div>
              <!-- @if (result.domains?.length > 0) {
                <i class="ph ph-check-circle text-green-500 w-6 h-6 shrink-0"></i>
              } -->
            </div>
          }
        }
      </div>
    }
  </div>
</div>
