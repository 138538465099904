import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NICKY_URLS } from 'src/app/utils/urls.constants';
import { NickyButtonComponent } from '../../form/nicky-button/nicky-button.component';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
    selector: 'app-terms-modal',
    imports: [MatTooltipModule, TranslateModule, NickyButtonComponent, A11yModule],
    templateUrl: './terms-modal.component.html'
})
export class TermsModalComponent {
  protected dialogRef = inject(DialogRef);
  protected data = inject(DIALOG_DATA);
  public termsOfUseUrl = NICKY_URLS.TERMS_OF_USE;
  public cookiePolicyUrl = NICKY_URLS.COOKIE_POLICY;
  public privacyPolicyUrl = NICKY_URLS.PRIVACY_POLICY;


}
