import { Injectable, inject } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { TOUR_STEPS } from './tour.config';
import { TranslateService } from '@ngx-translate/core';

interface TourStep {
  id: string;
  title: string;
  text: string;
  position: 'top' | 'bottom' | 'left' | 'right';
  beforeShow?: () => Promise<void>;
}

interface TourSection {
  [key: string]: TourSection | TourStep;
}

@Injectable({
  providedIn: 'root',
})
// TODO: handle multiple modals at once
export class TourService {
  protected shepherdService = inject(ShepherdService);
  protected translateService = inject(TranslateService);

  constructor() {
    this.initializeTour();
  }

  private readonly defaultOptions = {
    cancelIcon: { enabled: true },
    classes: 'shepherd-nicky-tour',
    scrollTo: true,
    modalOverlayOpeningRadius: 4,
    useModalOverlay: true,
  };

  private totalSteps = 0;
  private activeTourSteps: TourStep[] = [];


  startTour(sectionPath: string): void {
    // Prevent starting if tour is already active
    if (this.shepherdService.isActive) return;

    const tourSteps = this.prepareTourSteps(sectionPath);
    if (!tourSteps.length) return;

    const hiddenTourElements = document.querySelectorAll('.tour-hidden');

    hiddenTourElements.forEach(element => {
      element.classList.remove('tour-hidden');
      element.id = element.id.replace('-tour-hidden', '');
    });

    tourSteps.forEach(step => {
      const stepElement = document.querySelectorAll(`#${step.id}`);

      if (stepElement.length > 1) {
        this.deleteHiddenElements(stepElement);
      }
    })


    this.shepherdService.addSteps(tourSteps);
    this.shepherdService.start();
  }

  stopTour(): void {
    this.shepherdService.complete();
  }

  private deleteHiddenElements(elements: NodeListOf<Element>) {
    elements.forEach(element => {
      const { x, y, width, height } = element.getBoundingClientRect();
      if (x === 0 && y === 0 && width === 0 && height === 0) {
        element.classList.add('tour-hidden');
        element.id = `${element.id}-tour-hidden`;
      }
    });
  }

  private initializeTour(): void {
    this.shepherdService.defaultStepOptions = this.defaultOptions;
    this.shepherdService.modal = true;
  }

  private prepareTourSteps(sectionPath: string) {
    const [section, ...subsectionParts] = sectionPath.split('.');

    if (!this.isValidSection(section)) {
      console.error(`Invalid tour section: ${section}`);
      return [];
    }

    this.activeTourSteps = this.buildTourSteps(section as keyof typeof TOUR_STEPS, subsectionParts.join('.'));
    this.totalSteps = this.activeTourSteps.length;

    return this.activeTourSteps.map(this.createShepherdStep.bind(this));
  }

  private isValidSection(section: string): boolean {
    return section in TOUR_STEPS;
  }

  private createShepherdStep(step: TourStep, index: number) {
    let translatedText = this.translateService.instant('TOUR.' + step.text);

    if (translatedText && translatedText.includes('TOUR')) {
      translatedText = this.translateService.instant(step.text);
    }

    const isLastStep = index === this.totalSteps - 1;

    const shepherdStep: any = {
      id: step.id,
      attachTo: {
        element: `#${step.id}`,
        on: step.position as any,
      },
      buttons: this.getStepButtons(index),
      text: `
        ${translatedText}

        ${isLastStep ? `
          <picture (click)="goHome()" class="w-[90px] h-[42px]">
            <source  class="w-[90px] h-[42px]" srcset="assets/white-nicky-logo.svg" type="image/svg+xml" />
            <img  class="w-[90px] h-[42px]" src="/assets/white-nicky-logo.svg" alt="logo" />
          </picture>
        ` : ''}

        <div class="shepherd-dots">
          <span class="active">${index + 1} of ${this.totalSteps}</span>
        </div>
      `,
      beforeShowPromise: step.beforeShow,
    };

    const translatedTitle = this.translateService.instant('TOUR.' + step.title);
    if (translatedTitle && !translatedTitle.includes('TOUR')) {
      shepherdStep.title = translatedTitle;
    }

    return shepherdStep;
  }

  private buildTourSteps(section: keyof typeof TOUR_STEPS, subsectionPath?: string): TourStep[] {
    const steps: TourStep[] = [];
    let currentSection: TourSection = TOUR_STEPS[section] as TourSection;

    if (subsectionPath) {
      const nextSection = this.navigateToSubsection(currentSection, subsectionPath);
      if (!nextSection) return [];
      currentSection = nextSection;
    }

    this.addStepsRecursively(currentSection, steps);
    return steps;
  }

  private navigateToSubsection(startSection: TourSection, path: string): TourSection | null {
    let currentSection = startSection;
    const subsections = path.split('.');

    for (const subsection of subsections) {
      if (!this.isValidSubsection(currentSection, subsection)) {
        console.error(`Invalid subsection path: ${path}`);
        return null;
      }
      currentSection = currentSection[subsection] as TourSection;
    }

    return currentSection;
  }

  private isValidSubsection(section: TourSection, subsection: string): boolean {
    return section && subsection in section && this.isTourSection(section[subsection]);
  }

  private isTourSection(obj: any): obj is TourSection {
    return obj && typeof obj === 'object' && !('id' in obj);
  }

  private isTourStep(obj: any): obj is TourStep {
    return obj && typeof obj === 'object' && 'id' in obj && 'title' in obj && 'text' in obj && 'position' in obj;
  }

  private addStepsRecursively(section: TourSection, steps: TourStep[]): void {
    Object.values(section).forEach((item) => {
      if (this.isTourStep(item)) {
        steps.push(item);
      } else if (this.isTourSection(item)) {
        this.addStepsRecursively(item, steps);
      }
    });
  }

  private getStepButtons(index: number): any[] {
    const buttons = [];

    // Add exit button
    buttons.push({
      text: `${this.translateService.instant('TOUR.Exit')}`,
      classes: 'shepherd-button-secondary',
      action: () => this.shepherdService.complete(),
    });

    if (index > 0) {
      buttons.push({
        text: `${this.translateService.instant('back')}`,
        classes: 'shepherd-button-secondary',
        action: () => this.shepherdService.back(),
      });
    }

    const isLastStep = index === this.totalSteps - 1;
    buttons.push({
      text: `${this.translateService.instant(isLastStep ? 'finish' : 'next')}`,
      classes: 'shepherd-button-primary',
      action: () => (isLastStep ? this.shepherdService.complete() : this.shepherdService.next()),
    });


    return buttons;
  }

}
