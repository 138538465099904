import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/services/sign-in.service';
import { tap, catchError, EMPTY } from 'rxjs';
import { NickyButtonComponent } from 'src/app/components/form/nicky-button/nicky-button.component';


@Component({
    selector: 'app-forgot-password',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, NickyButtonComponent],
    providers: [
        SigninService
    ],
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  public userForgotPassword: boolean = false;

  private router: Router = inject(Router);
  private signInService: SigninService = inject(SigninService);
  private toastrService: ToastrService = inject(ToastrService);
  private translate: TranslateService = inject(TranslateService);

  constructor() {
    //FOR NOW lets set the default language to en on pages without authstore

    // Set default language
    this.translate.setDefaultLang('en');


    // Get browser language or fallback to en-US
    const browserLang = navigator.language || 'en' ;
    this.translate.use(browserLang);
  }

  public form: FormGroup = new FormGroup({
    nick: new FormControl(null, [Validators.required, Validators.email]),
  });

  redirectToForgotPassword() {
    if (this.router.url == '/login/forgotpassword') this.userForgotPassword = true;
  }

  validNick() {
    const currentNick = this.form.controls['nick'];
    if (currentNick.invalid || currentNick.value === undefined || currentNick.value.trim() === '') {
      return false;
    }
    return true;
  }

  backButton() {
    this.router.navigate(['/login']);
  }

  forgotPassword(nick: any) {
    const invalidNick = this.form.controls['nick'].invalid && this.form.controls['nick'].touched;

    if (invalidNick) {
      return;
    }

    this.signInService
      .resetPassword(nick)
      .pipe(
        tap((response: any) => {
          console.log(response);
        }),
        catchError((error: any) => {
          if (error.status.toString() == '200') {
            this.toastrService.success(
              this.translate.instant('LOGIN.weHaveSentAnEmailTo') +
                nick +
                this.translate.instant('LOGIN.inCaseItIsRegisteredPleaseCheckYourMailBox'),
              '',
              {
                timeOut: 3000,
              },
            );
          }
          if (!(error.status.toString() == '200')) {
            this.toastrService.error(error.statusText, '', {
              timeOut: 3000,
            });
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
