<nav class="bg-white relative border-b border-gray-200 border-solid">
  <div class="mx-auto max-w-full px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <!-- Logo and Navigation Links -->
      <div class="flex items-center justify-center sm:items-stretch sm:justify-start">
        <!-- Logo -->
        <div class="flex shrink-0 items-center">
          <picture (click)="goHome()" class="w-[102px] h-[42px]">
            <source srcset="assets/nicky-logo.svg" type="image/svg+xml" />
            <img class="h-9 mt-0.5 clickable" src="/assets/nicky-logo.svg" alt="logo" />
          </picture>
        </div>

        <div class="flex items-center divide-x">
          <!-- Route Title -->
          @if (routeTitle) {
            <div class="block my-auto">
              <div class="flex space-x-4">
                <div class="flex-grow border-r border-solid border-gray-300"></div>
                <div class="px-2">
                  <h1 class="font-inter text-sm md:text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                    {{ routeTitle | translate }}
                  </h1>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <!-- TODO Move tour header logics to a component -->
      @if (showHelpButton) {
        <app-nicky-button class="ml-auto border-r mr-1" size="extra-small" variant="text" (click)="startPageTour()">
          <mat-icon svgIcon="help-book" id="bookIcon"></mat-icon>
        </app-nicky-button>
      }

      <!-- Language Dropdown -->
      <app-nicky-button size="nav-button" variant="text">
        <app-dropdown #dropdown [responsiveLabel]="true" [dropdownEnd]="false">
          <div type="button" id="user-menu-button" dropdown-label aria-haspopup="menu">
            <span class="sr-only">Open language menu</span>
            <mat-icon svgIcon="{{ selectedLanguage }}"></mat-icon>
          </div>
          <div role="language-menu">
            @for (lang of systemLanguages; track lang.language) {
              <a class="dropdown-menu-item" role="language-item" (click)="changeLanguage(lang.language, dropdown)">
                <mat-icon [svgIcon]="lang.language" class="size-5"></mat-icon>
                <span
                  >{{ lang.name }} <span class="text-xs text-gray-500">{{ lang.language.toUpperCase() }}</span></span
                >
                @if (selectedLanguage === lang.language) {
                  <i class="ph ph-check size-5 ml-auto"></i>
                }
              </a>
            }
          </div>
        </app-dropdown>
      </app-nicky-button>
    </div>
  </div>
</nav>
