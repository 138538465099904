import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastrFactoryService {

  private toastr= inject(ToastrService)
  protected translate = inject(TranslateService);

  success(message: string, title?: string, options?: any) {
    this.toastr.success(message, title, { timeOut: 3000, ...options });
  }

  error(message: string, title?: string, options?: any) {
    this.toastr.error(message, title, { timeOut: 3000, ...options });
  }

  info(message: string, title?: string, options?: any) {
    this.toastr.info(message, title, { timeOut: 3000, ...options });
  }

  warning(message: string, title?: string, options?: any) {
    this.toastr.warning(message, title, { timeOut: 3000, ...options });
  }

  unknownError(key: string) {
    this.error(key);
  }

  handleError(error: any) {
    console.log(error);
    switch (error.status.toString()) {
      case '500':
        this.unknownError(error.statusText);
        break;
      case '400':
        if (error.error.errors.q == 'The q field is required.') {
          this.error(this.translate.instant('theFieldMustHaveAValueBeforeSubmit'));
        } else {
          this.error(error.error.errors);
        }
        break;
      case '404':
        this.error(error.statusText);
        break;
      default:
        this.unknownError(error.statusText);
    }
  }
}
