import { Component, inject } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { IconService } from './services/icon.service';
import { CheckForUpdateService } from './services/check-for-update';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})

export class AppComponent {  
  protected loadingService = inject(LoadingService);
  protected checkForUpdateService = inject(CheckForUpdateService);

  // TODO: Move to custom icons via tailwind css 
  // For now it is injected here so all components can use it
  public iconService = inject(IconService);
  
  loading$ = this.loadingService.loading$;
  title = 'nicky';
}
