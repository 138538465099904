import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as auth0 from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, Subscriber, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProfileDto } from '../dtos/user-profile.dto';
import FiatByCountry from '../utils/fiat-by-country';
import { UTMDto } from './../dtos/utm.dto';
import { UserService } from './user.service';
import { createHttpOptions } from '../utils/http-utils';

@Injectable()
export class SigninService {
  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
    audience: environment.auth0Audience,
  });


  public route = inject(Router);
  private http = inject(HttpClient);
  private toastrService = inject(ToastrService);
  private userService = inject(UserService);
  private fiatByCountry = inject(FiatByCountry);
  private translateService = inject(TranslateService);

  private success!: boolean;
  private accessToken!: string;
  private isDomainOwner!: boolean;
  private imageFile: any;

  public WebAuth(form: FormGroup<any>, isDomainOwner: boolean, imageFile: any): Observable<string> {
    this.isDomainOwner = isDomainOwner;
    this.imageFile = imageFile;
    return new Observable((observer) => {
      const website = this.setWebsite(form);
      this.setTerms(form);

      this.webAuth.signupAndAuthorize(this.getSignupOptions(form, website), (err, authResult) => {
        if (err) {
          this.handleAuthError(err, observer);
          return;
        }
        this.accessToken = authResult.accessToken;
        this.success = true;
        const domain = form.controls['domain'].value;
        const user: ProfileDto = this.createUserProfile(form, website);
        this.storeUserInfoInNickyDatabase(observer, domain, user, website);
      });
    });
  }

  private setWebsite(form: FormGroup<any>): string {
    const websiteControl = form.controls['website'];
    if (!websiteControl.value) {
      websiteControl.setValue('');
    }
    return this.formatWebsiteUrl(form);
  }

  private formatWebsiteUrl(form: FormGroup<any>): string {
    const website = form.controls['website'].value;
    if (!website.includes('https://') && !website.includes('http://')) {
      return form.controls['websiteCertificate'].value + website;
    }
    return website;
  }

  private setTerms(form: FormGroup<any>): void {
    if (form.controls['terms'].value === true) {
      form.controls['terms'].setValue('true');
    }
  }

  private getSignupOptions(form: FormGroup<any>, website: string) {
    return {
      connection: 'Username-Password-Authentication',
      email: form.controls['nick'].value,
      password: form.controls['password'].value,
      scope: 'openid profile',
      userMetadata: {
        lang: navigator.language || 'en',
      },
    };
  }

  private handleAuthError(err: any, observer: Subscriber<string>): void {
    if (err.statusCode === 400 && err.description === 'Invalid sign up') {
      observer.next('user already');
    } else {
      this.toastrService.error(err.description, '', { timeOut: 3000 });
      observer.next('error');
    }
    console.error(err);
  }

  private createUserProfile(form: FormGroup<any>, website: string): ProfileDto {
    return {
      agreedPrivacyPolicy: true,
      publicName: form.controls['publicName'].value,
      name: form.controls['yourName'].value,
      country: form.controls['country'].value,
      websiteUrl: website,
    };
  }

  public storeUserInfoInNickyDatabase(
    observer: Subscriber<string>,
    domain: string,
    userInfo: ProfileDto,
    website: string,
  ) {
    this.saveUserOnDatabase(userInfo, website)
      .pipe(
        switchMap(() =>
          // TODO: move to store, see who to pass the accessToken
          this.userService.setUserPreferedLanguage(this.translateService.getDefaultLang(), this.accessToken),
        ),
        switchMap(() => this.setFirstSettlementAsset(observer, userInfo, domain)),
        tap(() => this.handlePostRegistration(observer, domain)),
        catchError((e: any) => this.handleDatabaseError(e, observer)),
      )
      .subscribe();
  }

  private handlePostRegistration(observer: Subscriber<string>, domain: string): void {
    if (this.isDomainOwner) {
      this.addUserDomain(observer, domain);
    } else {
      this.showSuccessMessage();
      if (this.imageFile) {
        this.updateProfilePicture(observer);
        return;
      }
      observer.next('user success');
      localStorage.removeItem('utmSettings');
    }
  }

  private showSuccessMessage(): void {
    setTimeout(() => {
            //TODO: add translation
      this.toastrService.success(
        this.translateService.instant('registrationSuccessful'),
        '',
        {
          timeOut: 15000,
        },
      );
    }, 3000);
  }

  private handleDatabaseError(e: any, observer: Subscriber<string>): Observable<string> {
            //TODO: add translation
    this.toastrService.error(this.translateService.instant('somethingWentWrongOnSavingYourUserOnOurDatabase'), '', { timeOut: 3000 });
    observer.next('error on saving');
    console.error(e);
    return of('error');
  }

  private setFirstSettlementAsset(observer: Subscriber<string>, userInfo: ProfileDto, domain: string) {
    const asset = this.fiatByCountry.getUserFiatByCountry(userInfo.country?.toUpperCase() || 'US');
    return this.userService.setAcceptedAssets(asset, this.accessToken).pipe(
      catchError((e: any) => {
        console.error(e);
        return of('error');
      }),
    );
  }

  private updateProfilePicture(observer: Subscriber<string>) {
    // TODO: move to store why we are doing this here?
    // TODO: Important!
    this.userService
      .updateProfilePicture(this.imageFile)
      .pipe(
        tap((response: any) => {
          this.finalizeProfileUpdate(observer);
        }),
        catchError((error: any) => {
          this.finalizeProfileUpdate(observer);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private finalizeProfileUpdate(observer: Subscriber<string>): void {
    observer.next('user success');
    localStorage.removeItem('utmSettings');
  }

  private addUserDomain(observer: Subscriber<string>, domain: string) {
    this.addDomain(domain)
      .pipe(
        tap((response: any) => {
          if (this.imageFile) {
            this.updateProfilePicture(observer);
            return;
          }
          observer.next('user success');
          localStorage.removeItem('utmSettings');
        }),
        catchError((error: any) => {
          console.log(error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public activateAccount(form: FormGroup<any>): Observable<string> {
    return new Observable((observer) => {
      this.setTerms(form);
      this.webAuth.signupAndAuthorize(this.getSignupOptions(form, ''), (err, authResult) => {
        if (err) {
          this.handleAuthError(err, observer);
          return;
        }
        this.accessToken = authResult.accessToken;
        this.success = true;
        const domain = form.controls['domain'].value;
        this.addDomain(domain)
          .pipe(
            tap((response: any) => {
              this.showSuccessMessage();
              observer.next('user success');
            }),
          )
          .subscribe();
      });
    });
  }

  public saveUserOnDatabase(userInfo: ProfileDto, website: any) {
    const body = this.createUserDatabaseBody(userInfo, website);
    return this.http.post(
      environment.api + 'User/profile',
      body,
      createHttpOptions(undefined, undefined, this.accessToken),
    );
  }

  private createUserDatabaseBody(userInfo: ProfileDto, website: any) {
    const utmInfo: UTMDto = JSON.parse(localStorage.getItem('utmSettings') || '{}');
    const referrer = localStorage.getItem('referrer');
    return {
      agreedPrivacyPolicy: true,
      name: userInfo.name,
      publicName: userInfo.publicName,
      websiteUrl: website,
      country: userInfo.country || undefined,
      umtCampaign: utmInfo.utmCampaign || undefined,
      umtMedium: utmInfo.utmMedium || undefined,
      umtSource: utmInfo.utmSource || undefined,
      referrer: referrer || undefined,
    };
  }

  private addDomain(domain: string) {
    const body = JSON.stringify(domain);
    return this.http.post(
      environment.api + 'Domain/add',
      body,
      createHttpOptions(undefined, undefined, this.accessToken),
    );
  }

  public resetPassword(email: string): Observable<any> {
    let client_id = environment.auth0ID;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = {
      client_id: client_id,
      email: email,
      connection: 'Username-Password-Authentication',
    };
    return this.http.post(environment.auth0Domain + '/dbconnections/change_password', body, { headers });
  }
}
