<div
  class="flex items-center justify-between gap-2 px-2 py-1"
  [class]="customClasses"
  cdkOverlayOrigin
  #dropdownContainer
  (click)="$event.stopPropagation(); toggle()"
  #trigger="cdkOverlayOrigin"
>
  @if (label) {
    <div class="flex flex-col items-start">
      <span
        [ngClass]="{
          'hidden md:flex': responsiveLabel,
        }"
        >{{ label }}</span
      >
      <ng-content select="[dropdown-upsert]"></ng-content>
    </div>
    @if (dropdownEnd) {
      <div class="flex items-center gap-2">
        <span class="text-black notranslate">
          @if (dropdownOpen) {
            <i class="ph ph-caret-up clickable"></i>
          } @else {
            <i class="ph ph-caret-down clickable"></i>
          }
        </span>
      </div>
    }
  } @else {
    <ng-content select="[dropdown-label]"></ng-content>
    @if (dropdownEnd) {
      <div class="flex items-center gap-2">
        <span class="text-black notranslate">
          @if (dropdownOpen) {
            <i class="ph ph-caret-up clickable"></i>
          } @else {
            <i class="ph ph-caret-down clickable"></i>
          }
        </span>
      </div>
    }
  }
</div>
<ng-template
  (overlayOutsideClick)="dropdownOpen = false"
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayPositionStrategy]="positionStrategy"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayDisposeOnNavigation]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpen"
  (detach)="dropdownOpen = false"
  (backdropClick)="dropdownOpen = false"
>
  <div
    class="z-10 mt-2 mb-4 rounded-xl bg-white px-1.5 py-0 shadow-lg ring-1 ring-black/5 focus:outline-none gap-2"
    [ngClass]="{
      'w-[280px]': !fullWidth,
      'w-[calc(100vw-32px)] mx-4': fullWidth,
    }"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
