import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {concat, interval} from 'rxjs';
import {first} from 'rxjs/operators';

@Injectable({providedIn: 'root'})

export class CheckForUpdateService {
  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
    // const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixMinutes$ = interval(6 * 1000);
    // const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    const everySixMinutesOnceAppIsStable$ = concat(appIsStable$, everySixMinutes$); 
    everySixMinutesOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await updates.checkForUpdate();
        if (updateFound) {
          console.log('A new version is available. Clearing localStorage.');
          localStorage.clear();
          window.location.reload();
        } else {
          console.log('Already on the latest version.');
        }
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }
}