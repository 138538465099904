import { Component, inject, OnInit, OnDestroy, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthStore } from 'src/app/stores/auth.store';
import { DropdownComponent } from '../../../components/form/dropdown.component/dropdown.component';
import { systemLanguages } from 'src/environments/environment';
import { SearchBarPrivateComponent } from '../../../components/search-bar-private/search-bar-private.component';
import { filter } from 'rxjs/internal/operators/filter';
import { map, take } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TourService } from 'src/app/services/tour/tour.service';
import { NickyButtonComponent } from 'src/app/components/form/nicky-button/nicky-button.component';
import { UserProfilePictureComponent } from 'src/app/components/user-profile-picture/user-profile-picture.component';

interface NavLink {
  path?: string;
  icon: string;
  labelKey: string;
  id?: string;
  action?: () => void;
}

@Component({
    selector: 'app-header-private',
    imports: [
        RouterModule,
        TranslateModule,
        MatIconModule,
        DropdownComponent,
        SearchBarPrivateComponent,
        NickyButtonComponent,
        UserProfilePictureComponent,
    ],
    templateUrl: './header-private.component.html'
})
export class HeaderPrivateComponent implements OnInit, OnDestroy {
  public routeTitle: string = '';
  public showHelpButton = false;

  protected authStore = inject(AuthStore);
  protected tourService = inject(TourService);
  protected router = inject(Router);
  protected translate = inject(TranslateService);
  protected systemLanguages = systemLanguages;
  protected activatedRoute = inject(ActivatedRoute);

  private routerSubscription?: Subscription;

  @Input() fullWidthMode = false;

  isMobileMenuOpen = false;

  ngOnInit(): void {
    // Set the initial title
    this.routeTitle = this.getRouteTitle(this.activatedRoute);

    // Subscribe to NavigationEnd events to update the title on route changes
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null), // Triggers the subscription logic immediately
        map(() => {
          const routeTitle = this.getRouteTitle(this.activatedRoute);
          this.updateButtonVisibility(this.router.url);
          return routeTitle;
        }),
      )
      .subscribe((title) => {
        this.routeTitle = title;
      });
  }

  /**
   * Traverses the route tree to find the deepest child route and retrieves the title.
   * @param route The current activated route.
   * @returns The title of the route or 'Default Title' if not found.
   */

  private getRouteTitle(route: ActivatedRoute): string {
    let currentRoute: ActivatedRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    // Attempt to retrieve the title from routeConfig or data
    const routeConfigTitle = currentRoute.snapshot.routeConfig?.title;
    let dataTitle = '';
    currentRoute.title.pipe(take(1)).subscribe((title) => {
      dataTitle = title || '';
    });

    if (typeof routeConfigTitle === 'string') {
      return routeConfigTitle;
    } else if (typeof dataTitle === 'string') {
      return dataTitle;
    } else {
      return 'Default Title';
    }
  }

  changeLanguage(lang: string, dropdown: DropdownComponent) {
    // Update localStorage
    localStorage.setItem('userLanguage', lang);

    // Update translation service
    this.translate.use(lang);

    // Update auth store
    this.authStore.updateProfileLanguage({ language: lang });

    dropdown.close();
  }

  public navigationLinks: NavLink[] = [
    {
      path: '/overview',
      icon: 'ph ph-chart-pie-slice',
      labelKey: 'HEADER.OVERVIEW',
      id: 'overview',
    },
    {
      path: '/contacts',
      icon: 'ph ph-address-book',
      labelKey: 'contacts',
      id: 'contacts',
    },
    {
      path: '/settings',
      icon: 'ph ph-gear',
      labelKey: 'HEADER.SETTINGS',
      id: 'settings',
    },
  ];

  public profileMenuLinks: NavLink[] = [
    {
      icon: 'ph ph-chart-pie-slice',
      labelKey: 'HEADER.PROFILE',
      action: () => this.router.navigate(['/s', this.authStore.profile()?.shortId]),
      id: 'profile',
    },
    {
      path: '/settings',
      icon: 'ph ph-gear',
      labelKey: 'HEADER.SETTINGS',
      id: 'settings',
    },
    {
      icon: 'ph ph-sign-out',
      labelKey: 'HEADER.LOGOUT',
      action: () => this.authStore.logout(),
      id: 'logout',
    },
  ];

  onInputChange(event: any) {
    // Your search logic
  }

  onSearch(value: string, immediate: boolean) {
    // Your search execution logic
  }

  goHome() {
    this.router.navigate(['/overview']);
  }

  startPageTour() {
    const urlParams = new URLSearchParams(window.location.search);
    const currentUrl = this.router.url;
    const hasDomain = this.authStore.hasAvailableDomains();

    const tourMapping = new Map<string, string>([
      ['/payment-report?step=information', 'PAYMENT_REPORT.INFORMATION'],
      ['/payment-report?step=payment', 'PAYMENT_REPORT.PAYMENT'],
      ['/overview', 'OVERVIEW.MAIN'],
      ['/settings', hasDomain ? 'SETTINGS.OVERVIEW' : 'SETTINGS.OVERVIEW_NO_DOMAIN']
    ]);

    for (const [path, tourKey] of tourMapping) {
      const [route, query] = path.split('?');
      if (currentUrl.includes(route)) {
        if (!query || this.matchQueryParams(query, urlParams)) {
          this.tourService.startTour(tourKey);
          break;
        }
      }
    }
  }

  private matchQueryParams(query: string, urlParams: URLSearchParams): boolean {
    return query.split('&').every(param => {
      const [key, value] = param.split('=');
      return urlParams.get(key) === value;
    });
  }

  checkPaymentReportTour(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');
    return step === 'information' || step === 'payment';
  }

  updateButtonVisibility(currentUrl: string) {
    const allowedRoutes = ['/overview', '/settings', '/payment-report/'];

    if (currentUrl.startsWith('/payment-report')) {
      this.showHelpButton = this.checkPaymentReportTour();
    } else {
      this.showHelpButton = allowedRoutes.some((route) => currentUrl.startsWith(route));
    }
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;

    // Toggle body scroll
    if (this.isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  handleBackNavigation() {
    if (document.referrer && document.referrer.includes(window.location.hostname)) {
      window.history.back();
    } else {
      this.router.navigate(['/overview']);
    }
  }

  // Make sure to reset scroll when component is destroyed
  ngOnDestroy() {
    document.body.style.overflow = 'auto';

    this.routerSubscription?.unsubscribe();
  }
}
