import { Pipe, PipeTransform, inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'currencyTranslation',
  standalone: true,
  pure: false
})
export class CurrencyTranslationPipe implements PipeTransform, OnDestroy {
  private translate = inject(TranslateService);
  private asset$ = new BehaviorSubject<{ isFiat: boolean, assetName: string } | undefined>(undefined);
  private value$: Observable<string | undefined>;
  private subscription: Subscription;
  private i = 0;

  constructor() {
    this.value$ = this.asset$.pipe(
      map(asset =>
        asset?.isFiat
          ? this.translate.instant('CURRENCY_NAMES.' + this.processAssetName(asset.assetName))
          : asset?.assetName
      )
    );

    this.subscription = this.translate.onLangChange.subscribe(() => {
      const currentAsset = this.asset$.value;
      this.asset$.next(currentAsset);
    });
  }

  transform(asset: { isFiat: boolean, assetName: string } | undefined): Observable<string | undefined> {
    this.asset$.next(asset);
    return this.value$;
  }

  private processAssetName(assetName: string): string {
    const words = assetName.split(/\s+/);
    return words.map((word, index) => (index === 0 ? word.toLowerCase() : word)).join('');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
