import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface IconConfig {
  prefix: string;
  icons: string[];
  directory: string;
}

@Injectable({
  providedIn: 'root',
})

export class IconService {
  private matIconRegistry = inject(MatIconRegistry);
  private domSanitizer = inject(DomSanitizer);

  constructor() {
    this.registerAllIcons();
  }

  private readonly iconConfigs: IconConfig[] = [
    {
      prefix: '',
      directory: 'icons',
      icons: [
        'help-book', 'drop-button', 'copy-icon', 'profile-not-found',
        'add-wallet', 'success-payment', 'no-contacts', 'full-transactions',
        'pay', 'blank-pay', 'error', 'checked-user', 'paid-filter',
        'paid-filter-active', 'filter', 'active-filter', 'seal-check',
        'no-transactions', 'drop-button', 'arrow-up', 'missing-icon'
      ]
    },
    // TODO: Add SupportedAsset 'BTC' | 'ETH' | 'MATIC' | 'POL' | 'OTHER' from one service
    {
      prefix: '',
      directory: 'icons/paying-asset',
      icons: ['BRL', 'BTC', 'DKK', 'ETH', 'EUR', 'GBP', 'OTHER', 'SEK', 'USD', 'USDC', 'USDT']
    },
    // TODO: Add SupportedAsset 'BTC' | 'ETH' | 'MATIC' | 'POL' | 'OTHER' from one service
    {
      prefix: 'balance-',
      directory: 'icons/balance',
      icons: ['BRL', 'BTC', 'DKK', 'ETH', 'EUR', 'GBP', 'OTHER', 'SEK', 'USD', 'USDC', 'USDT']
    },
    // TODO: Add SupportedAsset 'BTC' | 'ETH' | 'MATIC' | 'POL' | 'OTHER' from one service
    {
      prefix: '',
      directory: 'icons/paying-asset',
      icons: [
        'BTC', 'ETH.USDC', 'MATIC.POL', 'USD', 'ETH.USDT', 'MATIC.USDC',
        'USDT', 'DKK', 'ETH', 'MATIC.USDT', 'EUR', 'POL', 'USDC',
        'BRL', 'GBP', 'OTHER', 'SEK'
      ]
    },
    {
      prefix: '',
      directory: 'icons/wallets',
      icons: [
        'metamask', 'trustwallet', 'exodus', 'coinbasewallet', 'wallet-manual', 'trustwallet'
      ]
    },
    {
      prefix: '',
      directory: 'icons/data-source',
      icons: ['coinbase', 'kraken', 'manual', 'foxbit']
    },
    {
      prefix: '',
      directory: 'icons/flags',
      icons: ['en', 'hl', 'ar', 'da', 'de', 'es', 'he', 'hi', 'nl', 'pt-br', 'zh-Hans']
    },
    {
      prefix: '',
      directory: 'icons/social',
      icons: [
        'facebook', 'instagram', 'twitter', 'linkedin', 'youtube',
        'apple', 'github', 'discord', 'microsoft', 'google'
      ]
    },
    {
      prefix: 'filter-',
      directory: 'icons/filter',
      icons: [
        'BTC', 'ETH.USDC', 'MATIC.POL', 'USD', 'ETH.USDT', 'MATIC.USDC',
        'USDT', 'DKK', 'ETH', 'MATIC.USDT', 'EUR', 'POL', 'USDC',
        'BRL', 'GBP', 'OTHER', 'SEK'
      ]
    },
    {
      prefix: 'status-',
      directory: 'icons/status',
      icons: ['success', 'error']
    }
  ];

  private registerAllIcons(): void {
    const svgSupported = this.isSvgSupported();

    this.iconConfigs.forEach(config => {
      this.registerIconSet(config, svgSupported);
    });
  }

  private registerIconSet(config: IconConfig, svgSupported: boolean): void {
    config.icons.forEach(iconName => {
      const iconFileName = config.prefix + iconName;
      const extension = svgSupported ? 'svg' : 'png';
      const iconUrl = `assets/${config.directory}/${iconFileName}.${extension}`;

      this.matIconRegistry.addSvgIcon(
        iconFileName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
      );

    });
  }

  private isSvgSupported(): boolean {
    const SVG_NS = 'http://www.w3.org/2000/svg';
    return !!document.createElementNS && !!document.createElementNS(SVG_NS, 'svg').createSVGRect;
  }
}
