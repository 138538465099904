import { btcAddress, ethAddress, polyAddress, bnbAddress } from "src/environments/environment";

export const NICKY_URLS = {
  ROOT: 'https://nicky.me',
  GOOGLE_AUTO_TRANSLATE_ROOT: 'https://nicky-me',
  TERMS_OF_USE: 'https://nicky.me/policies/terms-of-use/',
  PRIVACY_POLICY: 'https://nicky.me/policies/privacy-policy/',
  LEGAL_DISCLOSURE: 'https://nicky.me/policies/legal-disclosure',
  COOKIE_POLICY: 'https://nicky.me/policies/cookie-policy/',
  ABOUT: 'https://nicky.me/#about',
  TECHNOLOGY: 'https://nicky.me/#technology',
  REGISTRARS: 'https://nicky.me/#registrars',
  USERS: 'https://nicky.me/#users',
  CONTACT: 'https://nicky.me/#contact',
  TRANSLATION_LINK:
    '.translate.goog{{websitePath}}/?_x_tr_sl=en&_x_tr_tl={{targetLanguage}}&_x_tr_hl={{targetLanguage}}&_x_tr_pto=wapp',
  SOCIAL: {
    FACEBOOK: 'https://facebook.com/nickyofficial',
    INSTAGRAM: 'https://instagram.com/nickyofficial',
    TWITTER: 'https://twitter.com/nickyofficial',
    LINKEDIN: 'https://linkedin.com/company/nickyofficial',
    YOUTUBE: 'https://youtube.com/@nickyofficial',
  },
};

export function getLinkCryptoExplorerLink(assetId:string, cryptoAddress: string) {
  if (assetId.startsWith('BTC.')) {
    return btcAddress + 'address/' + cryptoAddress;
  }
  if (assetId.startsWith('ETH.')) {
    return ethAddress + 'address/' + cryptoAddress;
  }
  if (assetId.startsWith('MATIC.')) {
    return polyAddress + 'address/' + cryptoAddress;
  }
  if (assetId.startsWith('BEP.')) {
    return bnbAddress + 'address/' + cryptoAddress;
  }
  return '';
}
