/******************************************************************************
 * Angular Core Imports
 *****************************************************************************/
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

/******************************************************************************
 * Angular Material Imports
 *****************************************************************************/
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';

/******************************************************************************
 * Third-Party Library Imports
 *****************************************************************************/
// Authentication
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

// UI Components and Utilities
import { QRCodeModule } from 'angularx-qrcode';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

// Alyle UI Framework
import { LyButtonModule } from '@alyle/ui/button';
import { LyDialogModule } from '@alyle/ui/dialog';
import { LyIconModule } from '@alyle/ui/icon';
import { LyMenuModule } from '@alyle/ui/menu';
import { LySliderModule } from '@alyle/ui/slider';
import { LyTypographyModule } from '@alyle/ui/typography';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { LY_THEME, LY_THEME_NAME, LyHammerGestureConfig, LyTheme2, StyleRenderer } from '@alyle/ui';
import { MinimaDark, MinimaLight } from '@alyle/ui/themes/minima';

// Translations
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Config
import { customTooltipDefaults } from './config/tooltip.config';

/******************************************************************************
 * Application Imports
 *****************************************************************************/
// Core Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Interceptors
import { LoadingInterceptor } from './interceptors/loading.interceptor';

// Localization
import localeEN from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePT from '@angular/common/locales/pt';
import { DATE_FORMATS, environment } from 'src/environments/environment';
import { toggleMatTooltipDirective } from './directive/toggleMatTooltip.directive';
import { CurrencyTranslationPipe } from './pipes/currency-translation.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';


// Register locale data for internationalization
registerLocaleData(localePT);
registerLocaleData(localeEN);
registerLocaleData(localeEs);

@NgModule({
  // Component declarations
  declarations: [
    AppComponent
  ],
  // Bootstrap component
  bootstrap: [AppComponent],
  // Module imports
  imports: [
    // Angular core modules
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Angular Material modules
    MatNativeDateModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatTooltipModule,
    DragDropModule,
    // Routing module
    AppRoutingModule,
    // Third-party modules
    QRCodeModule,
    CurrencyMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxSliderModule,
    MatTooltipModule,
    toggleMatTooltipDirective,
    CurrencyTranslationPipe,
    ToastrModule.forRoot(),
    // Alyle UI modules
    LyButtonModule,
    LyDialogModule,
    LyIconModule,
    LyMenuModule,
    LySliderModule,
    LyTypographyModule,
    LyImageCropperModule,
    LyToolbarModule,
    // Translation module
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // Auth0 module
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0Audience,
        currentRoute: window.location.href,
        language: localStorage.getItem('defaultLang'),
        ui_locales: navigator.language || 'en',
        appState: {
          target: '/overview'
        }
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: environment.api + '*',
            allowAnonymous: true,
            tokenOptions: {
              authorizationParams: {
                audience: environment.auth0Audience,
              },
            },
          },
        ],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  // Service providers
  providers: [
    // HTTP interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    // Date formats and adapters
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // Theme and gesture configuration
    [LyTheme2],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: customTooltipDefaults,
    },
    // Application services
    provideEnvironmentNgxMask(),
    // LyDialog,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

// Factory function for translation loader
function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
