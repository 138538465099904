import { CommonModule } from '@angular/common';
import { Component, Input, ContentChild, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NickyButtonComponent } from 'src/app/components/form/nicky-button/nicky-button.component';

@Component({
    selector: 'app-error-state',
    imports: [CommonModule, NickyButtonComponent],
    templateUrl: './error-state.component.html'
})
export class ErrorStateComponent implements OnInit {
  protected route = inject(ActivatedRoute);
  protected translate = inject(TranslateService)

  @Input() title?: string = 'Something went wrong'; 
  @Input() subtitle?: string = 'We encountered an error while processing your request.';
  @Input() hasCustomButtons: boolean = false;
  @Input() backButtonText?: string = 'Go Back';
  @ContentChild('errorButtons') errorButtons: any;

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      console.log('Query Params:', params);
      if (params['reason'] === 'profile-not-found') {
        this.translate.get(['profileNotFound', 'theProfileYouAreLookingForDoesNotExistOrHasBeenRemoved', 'goBack'])
          .subscribe(translations => {
            console.log('Translations:', translations);
            this.title = translations['profileNotFound'] || 'Something went wrong';
            this.subtitle = translations['theProfileYouAreLookingForDoesNotExistOrHasBeenRemoved'] || 'We encountered an error while processing your request.';
            this.backButtonText = translations['back'] || 'Go Back';
          });
      }
    });
  }

  handleBackNavigation(): void {
    // Redirect to overview page
    window.location.href = '/overview'; // Update the path as necessary
  }
}
