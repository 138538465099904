<ng-container>
  @if (imageUrl && !isImageError && shortId) {
    <img
      [src]="imageUrl"
      [alt]="name"
      (error)="handleImageError()"
      (click)="handleOpenContact(shortId)"
      [class]="
        'flex-shrink-0 cursor-pointer hover:opacity-80 rounded-full object-cover ' +
        sizeClasses +
        ' ' +
        (className || '')
      "
    />
  } @else if (imageUrl && !isImageError) {
    <img
      [src]="imageUrl"
      [alt]="name"
      (error)="handleImageError()"
      [class]="
        'flex-shrink-0 cursor-pointer hover:opacity-80 rounded-full object-cover ' +
        sizeClasses +
        ' ' +
        (className || '')
      "
    />
  } @else if (initials && shortId) {
    <div
      (click)="handleOpenContact(shortId)"
      [class]="
        'flex-shrink-0 rounded-full bg-[#E5F5FF] flex  hover:opacity-80 items-center justify-center cursor-pointer ' +
        sizeClasses +
        ' ' +
        (className || '')
      "
    >
      {{ initials }}
    </div>
  } @else if (initials) {
    <div
      [class]="
        'flex-shrink-0 rounded-full bg-[#E5F5FF] flex  hover:opacity-80 items-center justify-center cursor-pointer ' +
        sizeClasses +
        ' ' +
        (className || '')
      "
    >
      {{ initials }}
    </div>
  } @else {
    <!-- Handle SSO user without profile picture and name -->
    <div
      [class]="
        ' flex-shrink-0 rounded-full bg-[#E5F5FF] flex items-center justify-center ' +
        sizeClasses +
        ' ' +
        (className || '')
      "
    >
      <i class="ph ph-user-circle text-2xl text-text-secondary"></i>
    </div>
  }
</ng-container>
