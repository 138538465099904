import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-profile-picture',
    imports: [CommonModule],
    templateUrl: './user-profile-picture.component.html'
})
export class UserProfilePictureComponent {
  private router = inject(Router);

  @Input() imageUrl?: string | SafeUrl | null;
  @Input() name: string | undefined = '';
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() className?: string;
  @Input() shortId?: string = '';
  isImageError = false;

  get initials(): string {
    return this.name
      ?.split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase()
      .slice(0, 2) || '';
  }

  get sizeClasses(): string {
    const sizes = {
      sm: 'h-8 w-8 text-xs',
      md: 'h-10 w-10 text-sm',
      lg: 'h-16 w-16 text-base',
      xl: 'h-24 w-24 text-lg',
    };
    return sizes[this.size];
  }

  handleOpenContact(shortId: string) {
    if (shortId) {
      this.router.navigate(['/s', shortId]);
    }
  }

  handleImageError() {
    this.isImageError = true;
  }
} 
