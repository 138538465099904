import { AuthStore } from 'src/app/stores/auth.store';
import FormatCurrency from 'src/app/utils/format-currency-utils';
import { effect, HostListener, inject, Injectable, signal } from '@angular/core';
import { AssetStore } from 'src/app/stores/asset.store';

@Injectable({
  providedIn: 'root',
})
export class FormatPriceUtils {

  private formatCurrency = inject(FormatCurrency);
  private assetStore = inject(AssetStore);


  public isCommaDecimal = signal(this.formatCurrency.isCommaDecimal(Intl.DateTimeFormat().resolvedOptions().locale || navigator.language));

  constructor() {
    effect(
      () => {
        this.updateCommaDecimal();
      },
      { allowSignalWrites: true }
    );
  }

  public updateCommaDecimal() {
    const userLanguage = navigator.language;
    console.log(userLanguage);
    console.log(Intl.DateTimeFormat().resolvedOptions().locale);
    this.isCommaDecimal.set(this.formatCurrency.isCommaDecimal(Intl.DateTimeFormat().resolvedOptions().locale || userLanguage));
    console.log(this.isCommaDecimal());
  }

  formatPrice(value: string | number, assetTicker: string) {
    let valueToString = value?.toString();

    if (!this.isCommaDecimal()) {
      value = parseFloat(valueToString?.replaceAll(',', ''));
    } else {
      if (valueToString?.includes('.') && Number.isNaN(Number(value))) {
        valueToString = valueToString?.replaceAll('.', '');
      }
      value = parseFloat(valueToString?.replace(',', '.'));
    }

    let decimal = this.assetStore.decimalPlacesFor()[assetTicker || 'USD'];

    const formatter = new Intl.NumberFormat(this.isCommaDecimal() ? 'pt-BR' : 'en-US', {
      maximumFractionDigits: decimal,
      minimumFractionDigits: decimal,
    });

    const finalValue = formatter.format(value).trim();

    return finalValue === 'NaN' ? '0' : finalValue;
  }

}
