import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { pipe, switchMap, tap } from 'rxjs';
import { PaymentReportModel } from '../components/models/payment.report.model';
import { ExportRequestBody, ExportType, FilterService } from '../services/filter.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


type ReportsStoreState = {
  loaded: boolean;
  pageSize: number;
  pageIndex: number;
  filters: {
    emails: string[];
    filterForm?: any;
    mustOrderByDescending: boolean;
  };
  total: number;
};

const initialState: ReportsStoreState = {
  loaded: false,
  pageSize: window.innerWidth >= 768 ? 10 : 5,
  pageIndex: 0,
  filters: {
    emails: [],
    mustOrderByDescending: true,
  },
  total: 0,
};

export const ReportsStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<PaymentReportModel>(),
  withState(() => {
    return {
      ...initialState
    }
  }),
  withComputed((store) => ({
    totalPages: computed(() => Math.ceil(store.total() / store.pageSize())),
    filtersAndPageSize: computed(() => ({
      ...store.filters(),
      pageSize: store.pageSize(),
      pageIndex: store.pageIndex(),
    })),
    calculatePaginationRange: computed(() => {
      const start = store.pageIndex() * store.pageSize() + 1;
      const end = Math.min((store.pageIndex() + 1) * store.pageSize(), store.total());
      return `${start} - ${end}`;
    })
  })),
  withMethods(
    (
      store,
      filterService = inject(FilterService),
      toastr = inject(ToastrService),
      translate = inject(TranslateService),
    ) => {
      const loadReports =  rxMethod<ReturnType<typeof store.filtersAndPageSize>>(
        pipe(
          switchMap((filtersAndPageSize) => {
            return filterService
              .getAllPaymentReportsWithoutFilters(
                filtersAndPageSize.pageSize,
                filtersAndPageSize.pageIndex,
                filtersAndPageSize.emails,
                {} as any,
                filtersAndPageSize.mustOrderByDescending,
              )
              .pipe(
                tapResponse({
                  next: ({ total, data }: { total: number, data: PaymentReportModel[] }) => {
                    // Add nick property to satisfy @ngrx/signals/entities
  
                    patchState(store, setAllEntities(data));
                    patchState(store, { total });
                    patchState(store, { loaded: true });
                  },
                  error: (error: { message: string }) => {
                    // TODO: Handle error appropriately
                    console.error('Error loading reports:', error);
                  },
                }),
              );
          }),
        ),
      );

      const previousPage = () => {
        patchState(store, {
          pageIndex: store.pageIndex() - 1
        });
      };

      const nextPage = () => {
        patchState(store, {
          pageIndex: store.pageIndex() + 1
        });
      };

      const changeItemsPerPage = (pageSize: number) => {
        patchState(store, {
          pageIndex: 0,
          pageSize,
        });
      };

      const exportData = rxMethod<{ type: ExportType; customBody?: Partial<ExportRequestBody> }>(
        pipe(
          switchMap(({ type, customBody }) => {
            return filterService
              .exportData(type, {
                ...customBody,
              })
              .pipe(
                tapResponse({
                  next: (response) => {
                    if (response.status === 200) {
                      toastr.success(translate.instant('exportSuccess'));
                    } else {
                      toastr.error(translate.instant('exportFailed'));
                    }
                  },
                  error: () => {
                    toastr.error(translate.instant('exportFailed'));
                  },
                }),
              );
          }),
        ),
      );


      return {
        loadReports,
        previousPage,
        nextPage,
        changeItemsPerPage,
        exportData,
      };
    },
  )
);
