import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'white' | 'danger' | 'secondaryDanger' | 'pill' | 'text';

export type ButtonSize = 'nav-button' | 'extra-small' | 'small-no-padding' | 'small' | 'medium-no-padding' | 'medium' | 'large';
export type ButtonState = 'default' | 'hover' | 'disabled';

@Component({
    selector: 'app-nicky-button',
    imports: [CommonModule, MatTooltipModule],
    template: `
    <button
      [disabled]="disabled"
      [type]="type"
      [class]="getButtonClasses()"
      [id]="id"
      class="[&:not(:first-child)]:ml-2 rounded-lg"
      [matTooltip]="getTooltipText()"
      [matTooltipDisabled]="!tooltip"
      [class.shrink-0]="iconLeft || iconRight"
    >
      @if (iconLeft) {
        <i [class]="iconLeft" [class.mr-2]="!!content.textContent?.trim()"></i>
      }

      <span class='text-left' [ngClass]="customLeftClasses" [class]="underline ? 'underline decoration-1' : ''" #content>
        <ng-content></ng-content>
      </span>

      @if (iconRight) {
        <i [class]="iconRight" class="ml-2"></i>
      }
    </button>
  `
})
export class NickyButtonComponent {
  private _id?: string;
  private translateService = inject(TranslateService);
  public isMobile: boolean = window.innerWidth < 640;

  @Input() variant: ButtonVariant = 'primary';
  @Input() size: ButtonSize = 'medium';
  @Input() customLeftClasses?: string;
  @Input() disabled = false;
  @Input() underline: boolean = false;
  @Input() iconLeft?: string;
  @Input() iconRight?: string;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() fullWidth = false;
  @Input() tooltip?: string;
  @Input() set id(value: string | undefined) {
    this._id = value ? value + '-button' : undefined;
  }

  get id(): string | undefined {
    return this._id;
  }

  getTooltipText(): string {
    return this.tooltip ? this.translateService.instant(this.tooltip) : '';
  }

  isFullWidth() {
    return this.fullWidth ? 'w-full' : '';
  }

  getButtonClasses(): string {
    const baseClasses = 'inline-flex items-center justify-center transition-colors font-medium';
    const widthClass = this.isFullWidth();

    const sizeClasses = {
      'nav-button': 'p-0 text-xs',
      'extra-small': 'px-2 py-1 text-xs',
      'small-no-padding': 'text-sm',
      small: 'px-3 py-1.5 text-sm',
      'medium-no-padding': '',
      medium: 'px-4 py-2',
      large: 'px-6 py-3 text-lg',
    };

    const variantClasses = {
      primary: {
        default: 'bg-black text-white hover:bg-button-primary-hover hover:border-button-primary-borderHover',
        disabled: 'bg-button-primary-disabled text-white',
      },
      secondary: {
        default: 'bg-white text-gray-900 border border-gray-200 hover:bg-button-secondary-hover hover:border-button-secondary-borderHover',
        disabled: 'bg-gray-100 text-gray-400 border-gray-200',
      },
      tertiary: {
        default: 'bg-gray-100 text-gray-900 hover:bg-button-tertiary-hover hover:border-button-tertiary-borderHover',
        hover: 'bg-gray-200',
        disabled: 'bg-gray-50 text-gray-400',
      },
      white: {
        default: 'bg-white text-gray-900 hover:bg-gray-50',
        hover: 'bg-gray-50',
        disabled: 'bg-gray-50 text-gray-400',
      },
      danger: {
        default: 'bg-red-600 text-white hover:bg-red-700',
        hover: 'bg-red-700',
        disabled: 'bg-red-200 text-red-100',
      },
      secondaryDanger: {
        default: 'bg-white border text-red-700 border-gray-200 hover:border-gray-300',
        hover: 'bg-red-700 text-white [&&]:border-red-700',
        disabled: 'bg-gray-100 text-gray-400 border-gray-200',
      },
      pill: {
        default: 'bg-white text-gray-900 border border-gray-200 hover:border-gray-300',
        hover: 'bg-gray-50 border-gray-300',
        disabled: 'bg-gray-100 text-gray-400 border-gray-200',
      },
      text: {
        default: 'text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-inset',
        hover: 'text-gray-900 bg-gray-100',
        disabled: 'text-gray-400',
      },
    };

    const state = this.disabled ? 'disabled' : 'default';
    return `
      ${baseClasses}
      ${sizeClasses[this.size]}
      ${variantClasses[this.variant][state]}
      ${widthClass}
      ${this.disabled ? 'cursor-not-allowed' : ''}
    `.trim();
  }
}
