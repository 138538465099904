// src/app/services/loading.service.ts
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, timer, Observable } from 'rxjs';
import { debounceTime, switchMap, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private loadingCount = 0;
  
  private debounceDelay = 250; // Increased to better handle quick calls
  private minDuration = 300; // Minimum time the loader should show

  loading$: Observable<boolean> = this.loadingSubject.pipe(
    distinctUntilChanged(),
    debounceTime(this.debounceDelay),
    takeUntilDestroyed(),
    switchMap(isLoading => {
      if (!isLoading) {
        return timer(0).pipe(map(() => false));
      }
      return timer(this.minDuration).pipe(
        map(() => this.loadingCount > 0)
      );
    })
  );

  setLoading(loading: boolean): void {
    if (loading) {
      this.loadingCount++;
    } else {
      this.loadingCount = Math.max(0, this.loadingCount - 1);
    }
    this.loadingSubject.next(this.loadingCount > 0);
  }

  isLoading(): boolean {
    return this.loadingCount > 0;
  }
}
