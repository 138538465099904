import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map } from 'rxjs/internal/operators/map';
import { Router } from '@angular/router';
export const homeGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return auth.isAuthenticated$.pipe(
    map((isAuthenticated: boolean) => isAuthenticated ? true : false),
    switchMap((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        router.navigate(['/overview']);
        return of(true);
      } else {
        router.navigate(['/login']);
        return of(true);
      }
    })
  );
}; 