import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const customTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: window.matchMedia('(max-width: 768px)').matches ? 90000 : 0,
  touchGestures: 'auto',
  position: 'above',
  touchendHideDelay: window.matchMedia('(max-width: 768px)').matches ? 90000 : 0,
  touchLongPressShowDelay: window.matchMedia('(max-width: 768px)').matches ? 0 : undefined,
  disableTooltipInteractivity: false
};
