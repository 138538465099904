import { FormatPriceUtils } from 'src/app/utils/format-price-utils';
import {
  Component,
  inject,
  OnInit,
  Input,
} from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/internal/operators/take';
import { NickyButtonComponent } from "../../../components/form/nicky-button/nicky-button.component";
import { DropdownComponent } from "../../../components/form/dropdown.component/dropdown.component";
import { MatIcon } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { systemLanguages } from 'src/environments/environment';

import { TourService } from 'src/app/services/tour/tour.service';

@Component({
    selector: 'app-header-public',
    imports: [NickyButtonComponent, DropdownComponent, MatIcon, TranslateModule],
    templateUrl: './header-public.component.html'
})
export class HeaderPublicComponent implements OnInit {

  get defaultLanguage() {
    const userLanguage = localStorage.getItem('userLanguage') || navigator.language;
    const detectedLanguage = systemLanguages.find(
      systemLanguage => systemLanguage.language === userLanguage
    );
    return detectedLanguage?.language || 'en';
  }

  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  protected translate = inject(TranslateService);
  protected tourService = inject(TourService);
  protected formatPriceUtils = inject(FormatPriceUtils);
  protected systemLanguages = systemLanguages;
  protected selectedLanguage = this.defaultLanguage;
  public showHelpButton = false;

  @Input() fullWidthMode = false;
  routeTitle: string = '';
  routerSubscription: any;

  ngOnInit(): void {
    // Set the     // Set the initial title
    this.routeTitle = this.getRouteTitle(this.activatedRoute);

    // Subscribe to NavigationEnd events to update the title on route changes
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(null),  // Triggers the subscription logic immediately
      map(() => {
        const routeTitle = this.getRouteTitle(this.activatedRoute);
        this.updateButtonVisibility(this.router.url);
        return routeTitle;
      }),
    ).subscribe(title => {
      this.routeTitle = title;
    });
  }


  /**
   * Traverses the route tree to find the deepest child route and retrieves the title.
   * @param route The current activated route.
   * @returns The title of the route or 'Default Title' if not found.
   */

  private getRouteTitle(route: ActivatedRoute): string {
    let currentRoute: ActivatedRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    // Attempt to retrieve the title from routeConfig or data
    const routeConfigTitle = currentRoute.snapshot.routeConfig?.title;
    let dataTitle = '';
    currentRoute.title.pipe(take(1)).subscribe((title) => {
      dataTitle = title || '';
    });

    if (typeof routeConfigTitle === 'string') {
      return routeConfigTitle;
    } else if (typeof dataTitle === 'string') {
      return dataTitle;
    } else {
      return 'Default Title';
    }
  }

  changeLanguage(lang: string, dropdown: DropdownComponent) {
    // Update localStorage
    localStorage.setItem('userLanguage', lang);

    // Update translation service
    this.translate.use(lang);

    // Update auth store
    this.selectedLanguage = lang;

    this.formatPriceUtils.updateCommaDecimal();
    dropdown.close();
  }

  goHome() {
    this.router.navigate(['/overview']);
  }

  checkPaymentReportTour(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');
    return step === 'information' || step === 'payment';
  }

  updateButtonVisibility(currentUrl: string) {
    if (currentUrl.startsWith('/payment-report')) {
      this.showHelpButton = this.checkPaymentReportTour();
    }
  }

  startPageTour() {
    const urlParams = new URLSearchParams(window.location.search);
    const currentUrl = this.router.url;

    const tourMapping = new Map<string, string>([
      ['/payment-report?step=information', 'PAYMENT_REPORT.INFORMATION'],
      ['/payment-report?step=payment', 'PAYMENT_REPORT.PAYMENT'],
    ]);

    for (const [path, tourKey] of tourMapping) {
      const [route, query] = path.split('?');
      if (currentUrl.includes(route)) {
        if (!query || this.matchQueryParams(query, urlParams)) {
          this.tourService.startTour(tourKey);
          break;
        }
      }
    }
  }

  private matchQueryParams(query: string, urlParams: URLSearchParams): boolean {
    return query.split('&').every(param => {
      const [key, value] = param.split('=');
      return urlParams.get(key) === value;
    });
  }


}
