import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { throwError } from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  protected loadingService = inject(LoadingService)

  private readonly EXCLUDED_URLS = [
    './assets/i18n/',
    '/public',
    'get-price',
    'BlockchainAsset/all',
    '/wallet/validateaddress',
    'User/isValidated?email='
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldExclude = this.EXCLUDED_URLS.some(url => req.url.includes(url));
    if (shouldExclude) {
      this.loadingService.setLoading(false);
      return next.handle(req);
    } else {
      this.loadingService.setLoading(true);
    }
    

    return next.handle(req).pipe(
      catchError(error => {
        console.error('Request error:', error);
        this.loadingService.setLoading(false);
        return throwError(() => error);
      }),
      finalize(() => {
        this.loadingService.setLoading(false);
        const timeoutId = setTimeout(() => {
          const isStillLoading = this.loadingService.isLoading();
          if (isStillLoading) {
            console.warn('Loader stuck detected - forcing close');
            this.loadingService.setLoading(false);
          }
        }, 5500);
      })
    );
  }
}
