<form
  [formGroup]="form"
  class="text-left max-w-md mx-auto p-6 space-y-4 min-h-[calc(100vh-150px)] flex flex-col justify-center"
>

  <button (click)="backButton()" class="w-fit rounded-full p-2 hover:bg-gray-100">
    <i class="ph ph-arrow-left"></i>
  </button>

  <div class="w-12 h-12 p-1 gap-0 rounded-lg flex items-center justify-center bg-[#E7E6E4] border border-[#E8E8E8]">
    <img class="h-9 mt-0.5 clickable" src="/assets/nicky-logo.svg" alt="logo" />
  </div>

  <h1 class="text-style-sheet-title">
    {{ 'LOGIN.forgotYourPassword' | translate }}
  </h1>

  <h3 class="text-style-sheet-subtitle">
    {{ 'LOGIN.enterYourEmailNickAndWeWill' | translate }}
  </h3>

  <div class="space-y-4">
    <input
      formControlName="nick"
      type="text"
      class="w-full px-4 py-2 border border-gray-300 rounded-lg form-input-base"
      placeholder="Email or username"
    />

    <div class="flex justify-end">
      <app-nicky-button variant="primary" (click)="forgotPassword(form.controls['nick'].value)">
        {{ 'continue' | translate }}
      </app-nicky-button>
    </div>
  </div>
</form>
