import { Component, inject } from '@angular/core';
import { Router, NavigationEnd, RouterModule, ActivatedRoute } from '@angular/router';
import { filter, map, startWith, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { NickyFooterComponent } from 'src/app/layouts/shared/nicky-footer/nicky-footer.component';
import { CommonModule } from '@angular/common';
import { HeaderPublicComponent } from 'src/app/layouts/layout-public/header-public/header-public.component';
import { AuthStore } from 'src/app/stores/auth.store';
import { HeaderPrivateComponent } from '../layout-private/header-private/header-private.component';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { systemLanguages } from 'src/environments/environment';

@Component({
    selector: 'app-layout-public',
    imports: [CommonModule, HeaderPublicComponent, HeaderPrivateComponent, NickyFooterComponent, RouterModule],
    templateUrl: './layout-public.component.html'
})
export class LayoutPublicComponent {
  fullWidthMode$!: Observable<boolean>;
  couldBeAuthenticated$!: Observable<boolean>;
  isAuthenticated$ = inject(AuthService).isAuthenticated$;
  private routerSubscription?: Subscription;
  protected authStore = inject(AuthStore);
  protected router = inject(Router);
  protected translate = inject(TranslateService);

  constructor() {
    // Set default language
    this.translate.setDefaultLang('en');

    // Get browser language or fallback to en-US
    const userLanguage = localStorage.getItem('userLanguage') || navigator.language;

    const detectedLanguage = systemLanguages.find(
      systemLanguage => systemLanguage.language === userLanguage
    );

    this.translate.use(detectedLanguage?.language || 'en');
  }

  ngOnInit() {
    this.authStore.currentUser();

    this.fullWidthMode$ = this.router.events.pipe(
      filter((event) => {
        return event instanceof NavigationEnd;
      }),
      map(() => this.getFullWidthMode()),
      startWith(this.getFullWidthMode()),
      distinctUntilChanged(),
    );

    this.routerSubscription = this.fullWidthMode$.subscribe();
  }

  getAuthLinkContents() {
    return this.translate
      .instant('signingUpForNickyTipcally')
      .replace("<a routerLink='/register'>", '')
      .replace('</a>', '');
  }

  shouldShowBanner(): boolean {
    const url = this.router.url;
    return url.startsWith('/s/') || url.startsWith('/d/') || url.startsWith('/e/');
  }

  goToRegister() {
    this.router.navigateByUrl('/register');
  }

  private getFullWidthMode(): boolean {
    return this.checkFullWidthMode(this.router.routerState.root);
  }

  private checkFullWidthMode(route: ActivatedRoute): boolean {
    if (route.snapshot.data['fullWidthMode']) {
      return true;
    }
    return route.firstChild ? this.checkFullWidthMode(route.firstChild) : false;
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }
}
