import { inject, Injectable } from '@angular/core';
import { Dialog, DialogConfig } from '@angular/cdk/dialog';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {

  // MOVED from MatDialog to CdkDialog
  private dialog = inject(Dialog);

  async openModal(component: any, data?: DialogConfig) {
    const dialogRef = this.dialog.open<any>(component, {
      width: '450px',
      maxWidth: '90vw',
      ...(data as any),
    });

    return await firstValueFrom(dialogRef.closed);
  }

}
