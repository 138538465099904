import { Component, ElementRef, EventEmitter, HostListener, inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { Subject, debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs';
import { SearchService } from '../../services/search.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserProfilePictureComponent } from '../user-profile-picture/user-profile-picture.component';
import { NickyButtonComponent } from '../form/nicky-button/nicky-button.component';

import { UserService } from 'src/app/services/user.service';

import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-search-bar-private',
    imports: [RouterModule, TranslateModule, MatIconModule, MatTooltipModule, UserProfilePictureComponent, NickyButtonComponent],
    templateUrl: './search-bar-private.component.html'
})
export class SearchBarPrivateComponent implements OnInit, OnDestroy {

  private router = inject(Router);
  private searchService = inject(SearchService);
  private userService = inject(UserService);
  private sanitizer = inject(DomSanitizer);
  
  @Output() search = new EventEmitter<string>();
  @ViewChild('searchContainer') searchContainer!: ElementRef;
  @ViewChild('searchInput') searchInput!: ElementRef;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    if (!this.isMobileSearchOpen && !this.searchContainer.nativeElement.contains(event.target) && this.showResults) {
      const clickedInside = this.searchContainer.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.clearSearch();
      }
    }
  }

  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();
  searchResults: any;
  showResults: boolean = false;
  searchTerm: string = '';
  isMobileSearchOpen = false;

  ngOnInit() {
    this.setupSearchSubscription();
    // Setup the debounced search
    this.searchSubject.pipe(
      debounceTime(700), // Wait 700ms after last input
      distinctUntilChanged(), // Only emit if value changed
      takeUntil(this.destroy$)
    ).subscribe(searchTerm => {
      this.search.emit(searchTerm);
    });
  }

  ngOnDestroy() {
    this.searchSubject.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onInputChange(event: any) {
    const term = event.target.value.trim();
    this.searchSubject.next(term);
  }

  private performSearch(term: string) {
    this.searchService.generalSearch(term.toLowerCase()).pipe(take(1)).subscribe({
      next: (results: any) => {
        // In general search we should only show the results if there is a exactly match
        this.handleGeneralSearchResponse(results, term);
      },
      error: (error) => {
        console.error('Search error:', error);
        this.searchResults = [];
      }
    });
  }

  private handleGeneralSearchResponse(response: any, term: string) {
    if (!response || !term) {
      this.searchResults = [];
      return;
    }

    const formattedSearchString = this.formatSearchString(term);
    this.searchResults = response;

    this.searchResults.forEach((result: any) => {
      if (result.hasProfilePicture) {
        // Assuming you have a method to get the profile image URL
        this.userService.getUserImageById(result.userId).subscribe((imageBlob) => {
          const safeUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(imageBlob as unknown as Blob));
          result.profileImageUrl = safeUrl;
        });
      }
    });

    if (this.searchResults?.length === 1) {
      const result = this.searchResults[0];
      result.exactlyMatch = this.isExactMatch(result, formattedSearchString);

      if (!result.exactlyMatch) {
        this.searchResults = [];
      }
    } else {
      this.searchResults = [];
    }
  }

  private formatSearchString(term: string): string {
    return term.includes('@') ? term.trim() : term.toUpperCase().trim();
  }

  private isExactMatch(result: any, searchString: string): boolean {
    return (
      searchString.toLowerCase() === result?.nick?.toLowerCase() ||
      searchString.toLowerCase() === result?.shortId?.toLowerCase() ||
      this.isSearchDomain(result, searchString)
    );
  }


  isSearchDomain(result: any, searchString: string): boolean {
    return (
      result.domains?.some(
        (domain: any) => domain.name.toLowerCase().trim() === searchString.toLowerCase().trim() && domain.isValid,
      ) || false
    );
  }

  private setupSearchSubscription() {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(term => {
      this.searchTerm = term;
      if (term.length >= 3) {
        this.performSearch(term);
      } else {
        this.searchResults = [];  
      }
      this.showResults = true;
    });
  }

  goToProfile(userShortId: string): void {
    // Clear the search input and results
    this.clearSearch();

    // Navigate to the user's profile using router.navigate with replaceUrl
    // This ensures proper navigation and browser history management
    this.router.navigate(['/s', userShortId], { replaceUrl: true });
  }

  toggleMobileSearch() {
    this.isMobileSearchOpen = !this.isMobileSearchOpen;
    if (!this.isMobileSearchOpen) {
      // Clear search results when closing mobile view
      this.searchResults = [];
      this.searchTerm = '';
      this.showResults = false;
    }
  }

  clearSearch(): void {
    this.searchResults = [];
    this.searchTerm = '';
    this.showResults = false;
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    this.searchSubject.next('');
  }
}
