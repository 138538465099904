<div class="fixed inset-0 bg-black/50 z-50 overflow-hidden flex items-center justify-center">
  <div class="w-full max-w-lg max-h-[90vh] bg-white rounded-xl shadow-lg flex flex-col mx-4 animate-modal-appear">
    <!-- Header -->
    <div class="p-4 sm:p-6 flex justify-between items-center border-b border-gray-200">
      <h3 class="font-semibold text-gray-900">{{ 'WelcomeToNickyWeAreHappyToHaveYou' | translate }}</h3>
      <button (click)="dialogRef.close(false)" class="text-gray-400 hover:text-gray-500 transition-colors">
        <i class="ph ph-x text-xl"></i>
      </button>
    </div>

    <!-- Body -->
    <div class="p-4 sm:p-6 space-y-4 overflow-y-auto flex-1">
      <p class="text-gray-600">
        {{ 'youNeedToAgreeWithThe' | translate }}
        <a [href]="termsOfUseUrl" target="_blank" class="text-blue-600 hover:text-blue-700 hover:underline">
          {{ 'REGISTER.termsOfUse' | translate }} </a
        >,
        <a [href]="cookiePolicyUrl" target="_blank" class="text-blue-600 hover:text-blue-700 hover:underline">
          {{ 'cookiePolicy' | translate }}
        </a>
        {{ 'REGISTER.and' | translate }}
        <a [href]="privacyPolicyUrl" target="_blank" class="text-blue-600 hover:text-blue-700 hover:underline">
          {{ 'privacyPolicy' | translate }}
        </a>
      </p>
    </div>

    <!-- Footer -->
    <div class="flex justify-end gap-3 p-4 sm:p-6 bg-gray-50 rounded-b-xl border-t border-gray-200">
      <app-nicky-button variant="secondary" (click)="dialogRef.close(false)">{{
        'decline' | translate
      }}</app-nicky-button>
      <app-nicky-button variant="primary" cdkFocusInitial (click)="dialogRef.close(true)">{{
        'accept' | translate
      }}</app-nicky-button>
    </div>
  </div>
</div>
